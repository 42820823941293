import React from "react";
import Lottie from "lottie-react";
import LottieFile from "./LottieFile.json";

const Loading = () => {
  return (
    <div className="">
      <Lottie
        animationData={LottieFile}
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  w-72 "
      />
    </div>
  );
};
export default Loading;
