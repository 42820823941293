// const CopyField = ({ title, value }) => {
//   return (
//     <div className="text-base text-textgray flex   justify-center   items-center">
//       <div className=" mr-4 font-light">
//         {title} : {value}
//       </div>
//       <svg
//         onClick={() => {
//           navigator.clipboard.writeText(value);
//           toast.success("คัดลอกเรียบร้อยแล้ว", {
//             position: "top-center",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//           });
//         }}
//         className="w-6 h-6 cursor-pointer"
//         fill="none"
//         stroke="currentColor"
//         viewBox="0 0 24 24"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <path
//           strokeLinecap="round"
//           strokeLinejoin="round"
//           strokeWidth={2}
//           d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
//         />
//       </svg>
//     </div>
//   );
// };
const ButtonNavStyle = "cursor-pointer p-2";

const SettingNavbar = ({ navs, setNavs }) => {
  return (
    <div>
      <div>
        <div className="my-4">
          <div className="topnav grid  md:grid-cols-4">
            <a
              onClick={() => setNavs("SYSTEM")}
              className={
                navs === "SYSTEM"
                  ? `${" active focus:outline-none " + ButtonNavStyle}`
                  : ButtonNavStyle
              }
              //   onClick={() => setNavs("Transfer")}
            >
              Color System
            </a>
            <a
              onClick={() => setNavs("GROUP")}
              className={
                navs === "GROUP"
                  ? `${" active focus:outline-none " + ButtonNavStyle}`
                  : ButtonNavStyle
              }
              //   onClick={() => setNavs("Witdraw")}
            >
              Color Group
            </a>
            <a
              onClick={() => setNavs("MODEL")}
              className={
                navs === "MODEL"
                  ? `${" active focus:outline-none " + ButtonNavStyle}`
                  : ButtonNavStyle
              }
              //   onClick={() => setNavs("BuyCoin")}
            >
              Manufacturer / Model
            </a>
            <a
              onClick={() => setNavs("CODE")}
              className={
                navs === "CODE"
                  ? `${" active focus:outline-none " + ButtonNavStyle}`
                  : ButtonNavStyle
              }
              //   onClick={() => setNavs("BuyCoin")}
            >
              Tinter Code
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingNavbar;
