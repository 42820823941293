import React, { memo, useState, useCallback } from "react";
import { Auth } from "aws-amplify";
import api from "../../api";
import swalAlert from "../../utils/swalAlert";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading/Loading";

const Title = ({
  label = "",
  placeholder = "",
  star = "",
  name,
  value,
  setField,
  type = "text",
}) => {
  return (
    <div className=" ">
      <div className="mb-2 ">
        {label} <span className=" text-textred">{star}</span>
      </div>
      <input
        className=" bg-gray-100  text-textgray rounded-md w-full px-4 py-2 outline-none"
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={(e) => setField(name)(e.target.value)}
      ></input>
    </div>
  );
};
const Register = memo(() => {
  // const [ingredientAddColor, setIngredientAddColor] = useState({});
  const [form, setForm] = useState({
    username: "",
    password: "",
    email: "",
    firstname: "",
    phone_number: "",
    lastname: "",
    telephone: "",
    fax: "",
    position: "",
    company: "",
    website: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });
  const [loading, setLoading] = useState(false);
  const router = useHistory();

  const onClickSubmit = async () => {
    if (form?.password === form?.re_password) {
      if (
        form?.username &&
        form?.password &&
        form?.company &&
        form?.country &&
        form?.email &&
        form?.firstname &&
        form?.lastname &&
        form?.phone_number &&
        form?.zip
      ) {
        try {
          setLoading(true);

          const format_phone_number = `+66${form.phone_number.slice(
            1,
            form.phone_number.length
          )}`;

          const checkValidateProfile = await api.get(
            `/profiles?username=${form.username}`
          );

          if (checkValidateProfile.data.profiles.length > 0) {
            await swalAlert.Warning({ text: "บัญชีนี้มีอยู่แล้ว" });
          } else {
            await Auth.signUp({
              username: `${form.username}`,
              password: `${form.password}`,
              attributes: {
                email: `${form.email}`,
                phone_number: `${format_phone_number}`,
                ["custom:firstname"]: `${form.firstname}`,
                ["custom:lastname"]: `${form.lastname}`,
                ["custom:telephone"]: `${form.telephone}`,
                ["custom:fax"]: `${form.fax}`,
                ["custom:position"]: `${form.position}`,
                ["custom:company"]: `${form.company}`,
                ["custom:website"]: `${form.website}`,
                ["custom:address_1"]: `${form.address_1}`,
                ["custom:address_2"]: `${form.address_2}`,
                ["custom:city"]: `${form.city}`,
                ["custom:state"]: `${form.state}`,
                ["custom:zip"]: `${form.zip}`,
                ["custom:country"]: `${form.country}`,
              },
            });
            // setForm(intitialStateRegister);
            await swalAlert.Success({ text: "สมัครสมาชิกสำเร็จ" });
            localStorage.clear();
            router.push("/Login");
            setLoading(false);
          }
        } catch (error) {
          console.error("error", error);
          await swalAlert.Fail({
            text:
              error?.response?.data?.message ||
              error?.message ||
              "Something went wrong",
          });
        } finally {
          setLoading(false);
        }
      } else {
        await swalAlert.Fail({
          text: "กรุณากรอกข้อมูลที่สำคัญให้ครบถ้วน",
        });
      }
    } else {
      await swalAlert.Fail({
        text: "กรุณากรอกข้อมูลให้ถูกต้อง รหัสผ่านไม่ตรงกัน",
      });
    }
  };

  const setField = useCallback(
    (field) => (e) => {
      const value = e && e.target ? e.target.value : e;
      setForm((currentForm) => ({
        ...currentForm,
        [field]: value,
      }));
    },
    [setForm]
  );

  const [inputs] = useState([
    {
      label: "Username",
      star: "*",
      placeholder: "Username",
      name: "username",
    },
    {
      label: "Email ",
      star: "*",
      placeholder: "Email ",
      name: "email",
    },
    {
      label: "First Name",
      star: "*",
      placeholder: "First Name",
      name: "firstname",
    },
    {
      label: "Last Name",
      star: "*",
      placeholder: "Last Name",
      name: "lastname",
    },

    {
      label: "Password ",
      star: "*",
      placeholder: "Password ",
      type: "password",
      name: "password",
    },
    {
      label: "Re-try Password  ",
      star: "*",
      placeholder: "Re-try Password   ",
      type: "password",
      name: "re_password",
    },
    {
      label: "Mobile Phone ",
      star: "*",
      placeholder: "Mobile   ",
      name: "phone_number",
    },
    {
      label: "Telephone ",
      placeholder: "Telephone  ",
      name: "telephone",
    },
    {
      label: "Fax",
      placeholder: "Fax ",
      name: "fax",
    },
    {
      label: "Position",
      placeholder: "Position",
      name: "position",
    },
    {
      label: "Company Name ",
      star: "*",
      placeholder: "Company Name ",
      name: "company",
    },
    {
      label: "Website",
      placeholder: "Website",
      name: "website",
    },
    {
      label: "Address 1",
      placeholder: "Address 1",
      name: "address_1",
    },
    {
      label: "Address 2",
      placeholder: "Address 2",
      name: "address_2",
    },
    {
      label: "City",
      placeholder: "City",
      name: "city",
    },
    {
      label: "State",
      placeholder: "State",
      name: "state",
    },
    {
      label: "Country ",
      star: "*",
      placeholder: "Country ",
      name: "country",
    },
    {
      label: "Zip Code",
      name: "zip",
      star: "*",
      placeholder: "Zipcode ",
    },
  ]);

  return (
    <div className="px-4 md:px-8 lg:px-16 pb-40 ">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 ">
        {inputs?.map((input, index) => {
          return (
            <div key={index}>
              <Title
                label={input.label}
                placeholder={input.placeholder}
                star={input.star}
                name={input.name}
                value={form[input.name]}
                setField={setField}
                type={input.type}
              />
            </div>
          );
        })}
      </div>
      {loading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <div className=" flex justify-center items-center space-x-4 mt-10">
          <button
            onClick={() => router.push(`/Login`)}
            className="bg-textgray px-10 w-40 h-14 cursor-pointer rounded-md grid items-center text-lg font-semibold text-white text-center hover:bg-textblue "
          >
            Cancel
          </button>
          <button
            onClick={() => onClickSubmit()}
            className="bg-bgtableblue px-10 h-14 w-40 rounded-md grid items-center text-lg font-semibold text-white text-center hover:bg-textblue "
          >
            Register
          </button>
        </div>
      )}
    </div>
  );
});
export default Register;
