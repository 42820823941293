import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useNavCtx } from "../../App";
import SettingNavbar from "../../components/Setting/SettingNavbar";
import ColorGroup from "./ColorGroup";
import ColorSystem from "./ColorSystem";
import Model from "./Model";
import TinterCode from "./TinterCode";

const SettingMain = () => {
  // const [pages, setPages] = useState("SYSTEM");
  const [navs, setNavs] = useState("SYSTEM");

  const router = useHistory();

  const { setCurrentNav } = useNavCtx();
  const pathName = router.location.pathname;

  useEffect(() => {
    setCurrentNav(pathName);
  }, [pathName]);

  // useEffect(async () => {
  // const checkNavbarSetting = localStorage.getItem("State");
  // if (checkNavbarSetting === Colour System") {
  //   setPages(Colour System");
  //   setNavs(Colour System");
  // } else if (checkNavbarSetting === "Manufacturer/ Model") {
  //   setPages("Manufacturer/ Model");
  //   setNavs("Manufacturer/ Model");
  // }
  // else if (checkNavbarSetting === Colour Group") {
  //   setPages(Colour Group");
  //   setNavs(Colour Group");
  // }
  //  else {
  //   setPages("Tinter Code");
  //   setNavs("Tinter Code");
  // }
  // }, []);
  // console.log("navs: ", navs);
  return (
    <div className=" mb-14 px-4 md:px-8 lg:px-16 ">
      <div className="text-xl">Settings</div>
      <div>
        <SettingNavbar navs={navs} setNavs={setNavs} />
      </div>
      {navs === "SYSTEM" && <ColorSystem />}
      {navs === "GROUP" && <ColorGroup />}
      {navs === "MODEL" && <Model />}
      {navs === "CODE" && <TinterCode />}
    </div>
  );
};
export default SettingMain;
