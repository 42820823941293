import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import swalAlert from "../../utils/swalAlert";
import { useDispatch, useSelector } from "react-redux";
import { loginThunk } from "../../redux/authSlice";
import Loading from "../../components/Loading/Loading";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // console.log("loading", loading);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const router = useHistory();
  // const location = useLocation();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      if (user.role === "admin") {
        router.push("/Admin");
      } else {
        router.push("/Color");
      }
    } else {
      return;
    }

  }, [user]);

  const onClickLogin = async () => {
    setLoading(true);

    if (username && password) {
      try {
        dispatch(loginThunk({ username, password }));
        setPassword("");
        setUsername("");
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        await swalAlert.Fail({ text: error.message });
      } finally {
        setLoading(false);
      }
    } else {
      setError(true);
      await swalAlert.Fail({ text: "โปรดกรอกไอดีและรหัสของท่าน" });
    }
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      onClickLogin();
    }
  };

  const logoClick = () => {
    window.location = "https://www.lezzon.com/"
  }
  

  if (loading) {
    return <Loading />;
  }
 


  return (
    <div className="px-4 md:px-8 lg:px-16 max-w-md w-full mx-auto  ">
      <div class=" mt-5 md:w-full md:h-full grid justify-items-center  ">
        <img onClick={()=>{logoClick()}} src="../logo/logo.png" className="md:w-full w-1/2 h-full cursor-pointer"></img>
        <h class=" md:text-base text-sm text-center text-gray-600 font-light mt-5">
          Welcome back! Please login to your account.
        </h>
      </div>
      <div class=" md:w-full flex flex-col mt-10 ">
        <div class=" text-textblue font-medium md:text-xl text-base ">
          Username
        </div>
        <input
          type="text"
          placeholder=""
          className=" focus:outline-none focus:ring focus:ring-textblue ring-1	rounded-sm p-3 w-full "
          value={username}
          onKeyPress={handleKeypress}
          onChange={(e) => setUsername(e.target.value)}
        />
        <div class=" text-textblue font-medium md:text-xl text-base mt-10  ">
          Password
        </div>
        <input
          type="password"
          placeholder=""
          onKeyPress={handleKeypress}
          className="focus:outline-none focus:ring focus:ring-textblue ring-1	rounded-sm p-3 w-full "
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className=" mt-10   ">
        {/* <a href="/"> */}
        {loading ? (
          <button className="bg-gray-500 px-10 h-14 w-full rounded-md grid items-center text-lg font-semibold text-white text-center  ">
            Logging in...
          </button>
        ) : (
          <button
            onClick={() => onClickLogin()}
            className="bg-bgtableblue px-10 h-14 w-full rounded-md grid items-center text-lg font-semibold text-white text-center hover:bg-textblue "
          >
            Login
          </button>
        )}

        <div className=" flex justify-between space-x-10 mt-10  ">
          <div
            onClick={(e) => {
              e.stopPropagation();
              router.push(`/Register`);
            }}
            className=" flex justify-center items-center  hover:underline text-textblue cursor-pointer"
          >
            Sign up
          </div>
          <div className=" flex justify-center items-center  hover:underline text-textblue cursor-pointer">
            <div
              onClick={(e) => {
                e.stopPropagation();
                router.push(`/ForgotPassword`);
              }}
            >
              Forget Password
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
