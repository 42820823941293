import { Fragment, useEffect, useMemo, useRef, useState, memo } from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";

export const CelesTableView = memo(
  ({
    isLoading,
    columns,
    data = [],
    rowAction,
    py = "3",
    sortTable,
    onClickUseIndex = false,
    bgcolor = " text-gray-500 bg-bgseccion",
    bgseccion = " bg-gray-100",
    bglightseccion = "bg-gray-50",
    hoverbg = " hover:bg-gray-100",
  }) => {
    const tableInstance = useTable({ columns: columns, data: data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      tableInstance;

    const onClickRow = (row, index) => {
      if (rowAction != undefined) {
        if (onClickUseIndex) rowAction(index);
        else rowAction(row);
      }
    };

    const onClickHeader = (field) => {
      if (sortTable !== undefined) {
        sortTable(field);
      }
    };

    return (
      <div className="bg-white flex shrink-0 pb-4  mb-6 ">
        <table
          {...getTableProps()}
          className="table  text-gray-500 border-separate   md:space-y-6 text-sm w-full"
        >
          <thead className="">
            {headerGroups.map((headerGroup) => (
              <tr className="    text-sm    ">
                {headerGroup.headers.map((column, index) => (
                  <th
                    onClick={() => onClickHeader(column.id)}
                    className={
                      "py-3 px-3   " +
                      (index === 0 ? "rounded-l-full" : "") +
                      " " +
                      (index === columns.length - 1 ? " rounded-r-full" : "") +
                      [bgcolor]
                    }
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="text-textgray text-sm font-medium"
          >
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => onClickRow(row, index)}
                  className={
                    "border-b-2 border-gray-100  cursor-pointer   " +
                    (index % 2 == 0 ? [bglightseccion] : [bgseccion]) +
                    [hoverbg]
                  }
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={
                          "py-" + [py] + " text-center whitespace-nowrap px-4 "
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
);
