import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";

export const CelesStandardPopUp = ({
  open,
  setOpen,
  panel,
  title,
  save = null,
  clearInput = null,
  createLoading = null,
  viewMode = false,
}) => {
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  const onSubmit = () => {
    if (save) {
      save();
    }
  };
  const onClose = () => {
    setOpen(false);

    if (clearInput) {
      clearInput();
    }
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0"
        open={open}
        onClose={onClose}
      >
        <div className="flex items-center justify-center min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0"
          >
            <div className="inline-block align-middle bg-white text-left z-20 shadow-xl transform transition-all rounded-lg">
              <div className="text-xl font-bold text-coolGray-800 p-4 px-10 pb-0">
                {title}
              </div>
              {panel}
              <div className="bg-white px-4    py-auto flex  justify-center fixed bottom-0 w-full rounded-lg">
                {!viewMode && (
                    <div></div>
                //   <button
                //     disabled={createLoading}
                //     type="button"
                //     className="w-24 my-2 inline-flex justify-center text-base rounded-md bg-waitingbuttoncolor text-white px-4 py-1 mx-4 font-medium  focus:outline-none"
                //     onClick={() => onSubmit()}
                //   >
                //     {createLoading ? "ยืนยัน..." : "ยืนยัน"}
                //   </button>
                )}
                <div></div>
                {/* <button
                  type="button"
                  className="w-24 my-2 inline-flex justify-center rounded-md  px-4 py-1 text-white text-base font-medium  bg-textlightgray focus:outline-none"
                  onClick={() => onClose()}
                >
                  ยกเลิก
                </button> */}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};