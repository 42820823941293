import React, { useState, useEffect } from "react";
import api from "../../api";
import {
  AddUrlToFile,
  getPathUrl,
  resizeFile,
  UploadFileAws,
} from "../../Functions";
import useSweetAlert from "../../hooks/useSweetAlert";
import useToastify from "../../hooks/useToastify";
import swalAlert from "../../utils/swalAlert";

const ColorGroup = () => {
  // upimage//
  const [file, setFile] = useState(null);

  const onClickUpload = async ({ target: { files } }) => {
    const file = await files[0];
    const resize = await resizeFile(file);
    setFile(resize);
  };

  // input//
  const [open, setOpen] = useState(false);
  const [currentSystemCodeForInput, setCurrentSystemCodeForInput] =
    useState("");
  const [systemCodes, setSystemCodes] = useState([]);

  const [loading, setLoading] = useState("");

  const { notifySuccess, notifyError } = useToastify();
  const { confirm } = useSweetAlert();

  useEffect(() => {
    LoadColorGroup();
  }, []);

  const LoadColorGroup = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/groups`);
      const data_table = data?.groups?.map((row) => {
        return {
          group: row?.group_name,
          id: row._id,
          //image
          img: row?.image_url,
        };
      });
      setSystemCodes(data_table);
    } catch (error) {
      console.error("ERROR TO LOAD COLOR GROUP  :", error);
    } finally {
      setLoading(false);
    }
  };
  //CreatColor+Image
  const onClickCreateColorsGroup = async () => {
    if (currentSystemCodeForInput) {
      setLoading(true);
      const image_key = await UploadFileAws(file);
      try {
        await api.post(`/groups`, {
          group_name: currentSystemCodeForInput,
          image_url: image_key,
        });
        setCurrentSystemCodeForInput("");
        // setImageUrl("");
        setFile(null);
        LoadColorGroup();
        notifySuccess();
      } catch (error) {
        console.error("ERROR TO DELETE GROUP :", error);
      } finally {
        setLoading(false);
      }
    } else {
      await swalAlert.Fail({ text: "Please enter the color name" });
    }
  };

  const clickDelete = async (id) => {
    await confirm({
      title: "Do you want to delete data?",
      callback: () => onClickToDeleteColorGroup(id),
    });
  };

  const onClickToDeleteColorGroup = async (id) => {
    try {
      await api.delete(`/groups/${id}`);
      LoadColorGroup();
      notifySuccess();
    } catch (error) {
      console.error("ERROR TO DELETE GROUP :", error);
    } finally {
      setLoading(false);
    }
  };
  // ////////
  const ColorGroupCode = ({ id, name = "", index, img }) => {
    return (
      <div className="px-4 py-2 bg-white rounded-lg flex  justify-between items-center my-2">
        <div className="flex justify-start items-center space-x-4">
          <img className="w-10 h-10 object-cover rounded-md" src={img} />
          <div>{name}</div>
        </div>
        <svg
          onClick={() => clickDelete(id)}
          className="w-6 h-6 text-textred cursor-pointer "
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          />
        </svg>
      </div>
    );
  };

  return (
    <div>
      <div className="flex justify-between items-center mt-10">
        <div className="text-xl">Color Group</div>
      </div>
      <div className="mt-10">
        <div>Color Group</div>
        <input
          className="  w-full md:w-96  focus:outline-none bg-bgseccion   px-4 py-2 rounded-lg"
          placeholder="Colour Group"
          value={currentSystemCodeForInput}
          onChange={(e) => setCurrentSystemCodeForInput(e.target.value)}
        />
        <div className="flex justify-start  items-start space-x-4 mt-2">
          <div>
            <div className=" max-w-sm w-full mt-6">
              <label for="button-file ">
                {file ? (
                  <div>
                    <img
                      className="w-20 h-20 object-cover rounded-md"
                      src={AddUrlToFile(file)}
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      className="w-20 h-20 object-contain border border-waitingbuttoncolor rounded-md"
                      src="../logo/logo.png"
                    />
                  </div>
                )}
                <div>
                  <div className="underline cursor-pointer mt-2 text-waitingbuttoncolor">
                    <input
                      accept="image/jpeg,image/png"
                      className=""
                      id="button-file"
                      type="file"
                      onChange={onClickUpload}
                    />
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div
          className="flex justify-center items-center space-x-2 md:w-40 ml-auto  rounded-lg p-2 cursor-pointer mt-6 text-white bg-waitingbuttoncolor"
          // onClick={onClickCreateColorsGroup}
          onClick={() => onClickCreateColorsGroup()}
        >
          <svg
            className="w-6 h-6  "
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
          <div>Add</div>
        </div>
      </div>
      <div className=" bg-bgseccion rounded-lg w-full p-4  h-80 overflow-y-scroll mt-6  ">
        {systemCodes?.map((systemCode, index) => {
          return (
            <div key={index}>
              <ColorGroupCode
                img={getPathUrl(systemCode.img)}
                name={systemCode.group}
                index={index}
                id={systemCode.id}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ColorGroup;
