import React, { memo, useState, useEffect } from "react";
import Select from "react-select";

import { AddUrlToFile, FunctionSumOfArray, getPathUrl } from "../../Functions";
import { CelesTableView } from "../../components/Table/CelesTableView";
import LezzonDropdown from "../../components/LezzonDropdown";
import Loading from "../../components/Loading/Loading";
import { CelesStandardPopUp } from "../../components/Popup/CelesStandardPopUp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Status from "../../components/status/Status";
import CardFinish from "../../components/Card/CardFinish";
import { FormulaColorTable } from "./FormulaColorTable";
import { useParams } from "react-router";
import api from "../../api";
import { useHistory } from "react-router-dom";
import { RenderRemarkComponent } from "../Admin/RequestInfo";

import {
  BackDoorLeft,
  BackDoorRight,
  BackWingLeft,
  BackWingRight,
  BonnetFront,
  BonnetRear,
  BumbperFront,
  BumbperRear,
  FrontDoorLeft,
  FrontDoorRight,
  FrontWingLeft,
  FrontWingRight,
  RoofCenter,
  SkirtFront,
  SkirtLeft,
  SkirtRear,
  SkirtRight,
} from "../../utils/calculateCarPart";
import ColorCreate from "./ColorCreate";
import { useSelector } from "react-redux";
import _ from "lodash";

export const RenderMenuBar = ({
  index,
  edit,
  setEdit,
  setCopy,
  id = "",
  showEditDone,
  isMine = false,
  isApiPublic = false,
  isApprove = false,
  isNoAction = false,
  isPrivate = false,
}) => {
  const [open, setOpen] = useState(false);
  const [isPublic, setIsPublic] = useState(false);

  const onClickChangeToPublic = async () => {
    try {
      await api.put(`/formulas/${id}`, {
        status: "Pending",
      });

      setIsPublic(true);
      setOpen(false);
    } catch (error) {
      console.error("Error to change to public :", error);
    }
  };
  return (
    <div className="space-x-4  justify-center items-center hidden xl:flex">
      {/* <button className=" w-28 my-2 inline-flex justify-center rounded-md  px-4 py-1 text-gray-700 text-base font-medium   bg-bgseccion focus:outline-none">
          Print
        </button> */}
      <button
        // onClick={copy}
        onClick={() => setCopy(true)}
        className=" w-28 my-2 inline-flex justify-center rounded-md  px-4 py-1 text-gray-700 text-base font-medium   bg-bgseccion focus:outline-none"
      >
        Copy
        {/* <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          /> */}
      </button>
      {isMine && (
        <button
          onClick={() => setEdit(!edit)}
          className=" w-28 my-2 inline-flex justify-center rounded-md  px-4 py-1 text-gray-700 text-base font-medium   bg-bgseccion focus:outline-none"
        >
          Edit
        </button>
      )}
      {isMine && isPrivate && isNoAction && !isPublic && (
        <div>
          <button
            onClick={() => setOpen(true)}
            // onClick={submit}
            className=" w-28 my-2 inline-flex justify-center rounded-md  px-4 py-1 text-gray-700 text-base font-medium   bg-bgseccion focus:outline-none"
          >
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
            Public
          </button>
          <CelesStandardPopUp
            hidden
            panel={
              <div className="relative w-75vh h-full  rounded-xl   ">
                <div className="m-4 flex flex-col justify-center items-center  space-y-2">
                  <div className="text-xl text-center ">
                  Do you confirm that you want to submit the request as Public?
                  </div>
                  <div className="flex justify-center items-center space-x-2">
                    <button
                      type="button"
                      className="w-24 my-2 inline-flex justify-center text-base rounded-md bg-waitingbuttoncolor text-white px-4 py-1 mx-4 font-medium  focus:outline-none"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickChangeToPublic();
                      }}
                    >
                      Confirm
                    </button>
                    <button
                      onClick={() => setOpen(false)}
                      className="w-24 my-2 inline-flex justify-center rounded-md  px-4 py-1 text-white text-base font-medium  bg-textlightgray focus:outline-none"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            }
            key={index}
            open={open}
            setOpen={setOpen}
          />
        </div>
      )}
    </div>
  );
};

export const RenderDetailSession = ({
  imageUrl,
  successTexts,
  TitleSuccess,
  image,
}) => {
  return (
    <div className="  bg-white   flex  flex-col lg:flex-row md:space-x-32    justify-between  items-center  max-w-6xl mx-auto mt-4 md:mt-0  ">
      <div className=" flex flex-col  w-full  max-w-sm  ">
        {/* <Status /> */}
        <label for="button-file ">
          {imageUrl ? (
            <div>
              <img
                className="w-full h-60 object-cover rounded-md"
                src={imageUrl}
              />
            </div>
          ) : (
            <div>
              <img
                className="w-full h-60 object-contain border border-waitingbuttoncolor rounded-md"
                src={image ? getPathUrl(image) : "../logo/logo.png"}
              />
            </div>
          )}
        </label>
      </div>
      <div className="  grid grid-cols-2 gap-4   mt-10 mx-auto max-w-lg w-full ">
        {successTexts?.map((successText, index) => {
          return (
            <div key={index}>
              <TitleSuccess name={successText.name} text={successText.text} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const RenderMunuBarMobile = ({
  showEditDone,
  setOpen,
  onSubmit,
  index,
  open,
  isMine = false,
}) => {
  return (
    <div className="md:space-x-4  justify-center items-center xl:hidden md:flex grid grid-cols-3 gap-4   mb-10">
      {/* <button className=" w-28 my-2 inline-flex justify-center rounded-md  px-4 py-1 text-gray-700 text-base font-medium   bg-bgseccion focus:outline-none">
          Print
        </button> */}
      {/* <button className=" w-28 my-2 inline-flex justify-center rounded-md  px-4 py-1 text-gray-700 text-base font-medium   bg-bgseccion focus:outline-none">
          Pring tag
        </button> */}
      <button
        // onClick={copy}
        className=" w-28 my-2 inline-flex justify-center rounded-md  px-4 py-1 text-gray-700 text-base font-medium   bg-bgseccion focus:outline-none"
      >
        Copy
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </button>
      {isMine && (
        <button
          onClick={()=>{showEditDone(true)}}
          className=" w-28 my-2 inline-flex justify-center rounded-md  px-4 py-1 text-gray-700 text-base font-medium   bg-bgseccion focus:outline-none"
        >
          Edit
        </button>
      )}

      {isMine && (
        <button
          onClick={() => setOpen(true)}
          // onClick={submit}
          className=" w-28 my-2 inline-flex justify-center rounded-md  px-4 py-1 text-gray-700 text-base font-medium   bg-bgseccion focus:outline-none"
        >
          <CelesStandardPopUp
            hidden
            panel={
              <div className="relative w-96 h-full  rounded-xl   ">
                <div className="m-4 flex flex-col justify-center items-center  space-y-2">
                  <div className="text-xl text-center ">
                  Do you confirm that you want to submit the request as Public?
                  </div>
                  <div className="flex justify-center items-center space-x-2">
                    <button
                      type="button"
                      className="w-24 my-2 inline-flex justify-center text-base rounded-md bg-waitingbuttoncolor text-white px-4 py-1 mx-4 font-medium  focus:outline-none"
                      onClick={onSubmit}
                    >
                      Confirm
                    </button>
                    <button
                      onClick={() => setOpen(false)}
                      className="w-24 my-2 inline-flex justify-center rounded-md  px-4 py-1 text-white text-base font-medium  bg-textlightgray focus:outline-none"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            }
            key={index}
            open={open}
            setOpen={setOpen}
          />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          Public
        </button>
      )}
    </div>
  );
};

export const RenderCalculateCarPart = ({
  quantity,
  data,
  selectedUnit,
  setSelectedUnit,
  finishColumns,
  setQuantity,
  size,
  setSize,
}) => {
  const [bumbperFront, setBumbperFront] = useState(false);
  const [frontWingLeft, setFrontWingLeft] = useState(false);
  const [frontWingRight, setFrontWingRight] = useState(false);
  const [bonnetFront, setBonnetFront] = useState(false);
  const [roofCenter, setRoofCenter] = useState(false);
  const [frontDoorLeft, setFrontDoorLeft] = useState(false);
  const [frontDoorRight, setFrontDoorRight] = useState(false);
  const [backDoorLeft, setBackDoorLeft] = useState(false);
  const [backDoorRight, setBackDoorRight] = useState(false);
  const [backWingLeft, setBackWingLeft] = useState(false);
  const [backWingRight, setBackWingRight] = useState(false);
  const [bonnetRear, setBonnetRear] = useState(false);
  const [bumbperRear, setBumbperRear] = useState(false);
  const [skirtFront, setSkirtFront] = useState(false);
  const [skirtLeft, setSkirtLeft] = useState(false);
  const [skirtRight, setSkirtRight] = useState(false);
  const [skirtRear, setSkirtRear] = useState(false);

  const [select, setSelect] = useState([]);

  useEffect(() => {
    if (select?.length > 0 && size === "S") {
      const size_s = select?.reduce((previous, current) => {
        return Number(current?.S) + previous;
      }, 0);
      setQuantity(size_s);
    } else if (select?.length > 0 && size === "M") {
      const size_m = select?.reduce((previous, current) => {
        return Number(current?.M) + previous;
      }, 0);
      setQuantity(size_m);
    } else if (select?.length > 0 && size === "L") {
      const size_l = select?.reduce((previous, current) => {
        return Number(current?.L) + previous;
      }, 0);
      setQuantity(size_l);
    } else if (select?.length > 0 && size === "XL") {
      const size_xl = select?.reduce((previous, current) => {
        return Number(current?.XL) + previous;
      }, 0);
      setQuantity(size_xl);
    } else {
      setQuantity(0);
    }
  }, [
    select,
    bumbperFront,
    frontWingLeft,
    frontWingRight,
    bonnetFront,
    roofCenter,
    frontDoorLeft,
    frontDoorRight,
    backDoorLeft,
    backDoorRight,
    backWingLeft,
    backWingRight,
    bonnetRear,
    bumbperRear,
    skirtFront,
    skirtLeft,
    skirtRight,
    skirtRear,
    size,
  ]);

  const onClickResetPath = () => {
    setBumbperFront(false);
    setFrontWingLeft(false);
    setFrontWingRight(false);
    setBonnetFront(false);
    setRoofCenter(false);
    setFrontDoorLeft(false);
    setFrontDoorRight(false);
    setBackDoorLeft(false);
    setBackDoorRight(false);
    setBackWingLeft(false);
    setBackWingRight(false);
    setBonnetRear(false);
    setBumbperRear(false);
    setSkirtFront(false);
    setSkirtLeft(false);
    setSkirtRight(false);
    setSkirtRear(false);
    setSelect([]);
  };

  const onClickSelect = (object, value, setValue) => {
    setValue(!value);
    const findIndex = select?.findIndex((d) => d.name === object.name);
    if (findIndex >= 0) {
      select?.splice(findIndex, 1);
    } else {
      select?.push(object);
    }
  };

  const buttonStyle = "border border-gray-400 rounded-md h-14";
  const buttonStyleActive =
    "border border-gray-400 rounded-md bg-blue-500 h-14";
  const buttonStyleInActive = "border border-gray-400 rounded-md h-14 ";

  return (
    <div className="">
      ● Please Select Size
      <div className="h-[52px] grid grid-cols-5 gap-5 mt-2 mb-2 ">
        <button
          onClick={() => {
            setSize("");
            onClickResetPath();
          }}
          className={size === "" ? buttonStyleActive : buttonStyleInActive}
        >
          Non-size
        </button>
        <button
          onClick={() => setSize("S")}
          className={size === "S" ? buttonStyleActive : buttonStyleInActive}
        >
          S
        </button>
        <button
          onClick={() => setSize("M")}
          className={size === "M" ? buttonStyleActive : buttonStyleInActive}
        >
          M
        </button>
        <button
          onClick={() => setSize("L")}
          className={size === "L" ? buttonStyleActive : buttonStyleInActive}
        >
          L
        </button>
        <button
          onClick={() => setSize("XL")}
          className={size === "XL" ? buttonStyleActive : buttonStyleInActive}
        >
          XL
        </button>
      </div>
      <div className="mb-2 mt-7">● Please Select Part</div>
      <div className="grid grid-cols-6 gap-5 ">
        <button className={buttonStyle} onClick={() => onClickResetPath()}>
          Non
        </button>

        <button
          onClick={() =>
            onClickSelect(BumbperFront, bumbperFront, setBumbperFront)
          }
          className={bumbperFront ? buttonStyleActive : buttonStyleInActive}
        >
          1. Front Bumper
        </button>
        <button
          onClick={() =>
            onClickSelect(FrontWingLeft, frontWingLeft, setFrontWingLeft)
          }
          className={frontWingLeft ? buttonStyleActive : buttonStyleInActive}
        >
          2. Front Fender- Left
        </button>

        <button
          onClick={() =>
            onClickSelect(FrontWingRight, frontWingRight, setFrontWingRight)
          }
          className={frontWingRight ? buttonStyleActive : buttonStyleInActive}
        >
          3. Front Fender- Right
        </button>

        <button
          onClick={() =>
            onClickSelect(BonnetFront, bonnetFront, setBonnetFront)
          }
          className={bonnetFront ? buttonStyleActive : buttonStyleInActive}
        >
          4. Hoods
        </button>
        <button
          onClick={() => onClickSelect(RoofCenter, roofCenter, setRoofCenter)}
          className={roofCenter ? buttonStyleActive : buttonStyleInActive}
        >
          5. Roof
        </button>
        <button
          onClick={() =>
            onClickSelect(FrontDoorLeft, frontDoorLeft, setFrontDoorLeft)
          }
          className={frontDoorLeft ? buttonStyleActive : buttonStyleInActive}
        >
          6. Front Door- Left
        </button>
        <button
          onClick={() =>
            onClickSelect(FrontDoorRight, frontDoorRight, setFrontDoorRight)
          }
          className={frontDoorRight ? buttonStyleActive : buttonStyleInActive}
        >
          7. Front Door- Right
        </button>
        <button
          onClick={() =>
            onClickSelect(BackDoorLeft, backDoorLeft, setBackDoorLeft)
          }
          className={backDoorLeft ? buttonStyleActive : buttonStyleInActive}
        >
          8. Back Door- Left
        </button>
        <button
          onClick={() =>
            onClickSelect(BackDoorRight, backDoorRight, setBackDoorRight)
          }
          className={backDoorRight ? buttonStyleActive : buttonStyleInActive}
        >
          9. Back Door- Right
        </button>
        <button
          onClick={() =>
            onClickSelect(BackWingLeft, backWingLeft, setBackWingLeft)
          }
          className={backWingLeft ? buttonStyleActive : buttonStyleInActive}
        >
          10. Back Fender- Right
        </button>
        <button
          onClick={() =>
            onClickSelect(BackWingRight, backWingRight, setBackWingRight)
          }
          className={backWingRight ? buttonStyleActive : buttonStyleInActive}
        >
          11. Back Fender- Left
        </button>
        <button
          onClick={() => onClickSelect(BonnetRear, bonnetRear, setBonnetRear)}
          className={bonnetRear ? buttonStyleActive : buttonStyleInActive}
        >
          12. Trunk Lid
        </button>
        <button
          onClick={() =>
            onClickSelect(BumbperRear, bumbperRear, setBumbperRear)
          }
          className={bumbperRear ? buttonStyleActive : buttonStyleInActive}
        >
          13. Back Pumper
        </button>
        <button
          onClick={() => onClickSelect(SkirtFront, skirtFront, setSkirtFront)}
          className={skirtFront ? buttonStyleActive : buttonStyleInActive}
        >
          14. Back Pumper
        </button>
        <button
          onClick={() => onClickSelect(SkirtLeft, skirtLeft, setSkirtLeft)}
          className={skirtLeft ? buttonStyleActive : buttonStyleInActive}
        >
          15. Left skirt
        </button>
        <button
          onClick={() => onClickSelect(SkirtRight, skirtRight, setSkirtRight)}
          className={skirtRight ? buttonStyleActive : buttonStyleInActive}
        >
          16. Front skirt
        </button>
        <button
          onClick={() => onClickSelect(SkirtRear, skirtRear, setSkirtRear)}
          className={skirtRear ? buttonStyleActive : buttonStyleInActive}
        >
          17. Back skirt
        </button>
      </div>
      {size && quantity > 0 && (
        <FormulaColorTable
          selectedUnit={selectedUnit}
          setSelectedUnit={setSelectedUnit}
          finishcolors={data}
          finishColumns={finishColumns}
          setQuantity={setQuantity}
          quantity={quantity}
        />
      )}
    </div>
  );
};

export const ColorEdit = () => {
  const index = 0;
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState();

  const [data, setData] = useState([]);
  // console.log("data", data);

  const [quantity, setQuantity] = useState(0);

  const [finishcolors, setFinishcolors] = useState([]);
  // console.log("finishcolors", finishcolors);

  const [successTexts, setSuccessTexts] = useState([]);
  const [size, setSize] = useState("");
  const [open, setOpen] = useState(false);
  const [copy, setCopy] = useState(false);

  const params = useParams();
  const router = useHistory();

  const { user } = useSelector((state) => state.auth);

  const isMine = data?.maker?.id === user?._id;

  useEffect(async () => {
    // console.log("selectedUnit: ", selectedUnit);
    if (data) {
      const all_weight_gram = FunctionSumOfArray(data?.colors);

      let countMilliter = 0;
      let countMilliter2 = 0;

      let totalWeightMilliliter = _.sumBy(
        data?.colors,
        (item) =>
          (Number(item?.gram / all_weight_gram) * quantity) / item?.density
      );

      const apiColor = await data?.colors?.map((row) => {
        if (selectedUnit.value === "grams") {
          const percent =
            Number((row?.gram / all_weight_gram) * 100)?.toFixed(2) + " %";

          const new_gram =
            Number(Number((row?.gram / all_weight_gram) * 100) / 100) *
            quantity;

          const new_weight_gram = Number((new_gram * quantity) / 1000).toFixed(
            2
          );

          // console.log("row", row);
          // console.log("percent", percent);
          // console.log("new_gram", new_gram);
          // console.log("new_weight_gram", new_weight_gram);
          // console.log("--------------------------------------------");

          const milliter = Number(new_gram) + Number(countMilliter);
          countMilliter += Number(new_gram);

          return {
            code: row?.code,
            name: row?.name,
            weight:
              quantity < 1 ? Number(0).toFixed(2) : Number(new_gram).toFixed(2),

            milliter:
              quantity < 1 ? Number(0).toFixed(2) : Number(milliter).toFixed(2),
            percent: percent,
          };
        } else {
          // milliliter
          const percent =
            Number((row?.gram / all_weight_gram) * 100).toFixed(2) + " %";

          const new_weight_milliliter =
            Number(Number((row?.gram / all_weight_gram) * 100) / 100) *
            quantity;
          // (Number(row?.gram / all_weight_gram) * quantity).toFixed(2) /
          // Number(row?.density).toFixed(2);

          // const retStep2 =
          //   Number(new_weight_milliliter / totalWeightMilliliter) * 1000;

          const retStep3 = Number(new_weight_milliliter) * Number(row?.density);

          const milliter2 = Number(retStep3) + Number(countMilliter2);
          countMilliter2 += Number(retStep3);

          // console.log("row", row);
          // console.log("percent", percent);
          // console.log("new_weight_milliliter", new_weight_milliliter);
          // // console.log("retStep2", retStep2);
          // console.log("retStep3", retStep3);
          // console.log("--------------------------------------------");

          return {
            code: row?.code,
            name: row?.name,
            weight:
              quantity < 1 ? Number(0).toFixed(2) : Number(retStep3).toFixed(2),
            milliter:
              quantity < 1
                ? Number(0).toFixed(2)
                : Number(milliter2).toFixed(2),
            percent: percent,
          };
        }
      });

      setFinishcolors(apiColor);
    } else {
      return null;
    }
  }, [data, quantity, selectedUnit]);

  useEffect(() => {
    LoadColor();
  }, []);

  useEffect(() => {
    if (data) {
      setSuccessTexts([
        {
          name: "Colour Code",
          text: data?.color_code,
        },
        {
          name: "Colour Ref",
          text: data?.color_ref,
        },
        {
          name: "Colour Name",
          text: data?.color_name,
        },
        {
          name: "Colour System",
          text: data?.color_system?.label,
        },
        {
          name: "Colour Group",
          text: data?.color_group?.label,
        },
        {
          name: "Manufactuere",
          text: data?.manufacturer?.label,
        },
        {
          name: "Model",
          text: data?.model?.label,
        },
        {
          name: "Country",
          text: data?.country?.label,
        },
        {
          name: "Region",
          text: data?.region?.label,
        },
        {
          name: "Year",
          text: data?.year?.label,
        },
      ]);
    } else {
      return;
    }
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [edit]);

  const LoadColor = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/formulas/${params.id}`);
      setData(data?.formula);
    } catch (error) {
      console.error("Error to load:", error);
    } finally {
      setLoading(false);
    }
  };

  const showEditDone = (value) => {
    setEdit(value);
    setCopy(value);
  };
  //Toastify//
  const notify = () => toast.info("ส่งคำขอของคุณเรียบร้อยแล้ว");
  // const copy = () => toast.info("copied !");
  //table//

  const finishColumns = [
    { Header: "Tinter Code", accessor: "code" },
    { Header: "Tinter Name ", accessor: "name" },
    { Header: "Weight(grams) ", accessor: "weight" },
    { Header: "Weight Accumulated(gram)", accessor: "milliter" },
    { Header: "%", accessor: "percent" },
  ];

  const onSubmit = () => {
    setOpen(false);
    notify();
  };

  const TitleSuccess = memo(({ name = "", text = "" }) => {
    return (
      <div className="  ">
        <div className="mb-2  ">{name} </div>
        <div className="text-textgray line-clamp-1 ">{text} </div>
      </div>
    );
  });

  const Breadcrumb = memo(({ name, name2 }) => {
    return (
      <div className="flex md:justify-center items-center space-x-4 text-textlightgray">
        <a onClick={() => router.push(`/Color`)}>
          <div className=" cursor-pointer ">Color Online</div>
        </a>
        <div className={name ? "" : "text-textblue"}>
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>

        <div
          onClick={() => router.push(`/Color`)}
          className={
            name2
              ? " cursor-pointer "
              : "text-textblue cursor-pointer hover:underline "
          }
        >
          {name}
        </div>
        {name2 && (
          <React.Fragment>
            <div className="text-textblue">
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
            <div className="text-textblue cursor-pointer hover:underline ">
              {name2}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  });

  if (loading) return <Loading />;

  if (edit) {
    return (
      <div>
        <RenderMenuBar
          isApprove={data?.status === "Approve"}
          isApiPublic={data?.type === "PUBLIC"}
          isNoAction={data?.status === ""}
          isPrivate={data?.type === "PRIVATE"}
          isMin={isMine}
          // copy={copy}
          setCopy={setCopy}
          showEditDone={showEditDone}
          setOpen={setOpen}
          onSubmit={onSubmit}
          index={index}
          open={open}
          id={data?._id}
          edit={edit}
          setEdit={setEdit}
        />
        <ColorCreate previous={data} setEdit={setEdit} showEditDone={showEditDone} mode="EDIT" />
      </div>
    );
  } else if (copy) {
    return (
      <div>
        <ColorCreate previous={data} setEdit={setEdit} showEditDone={showEditDone} mode="COPY" />
      </div>
    );
  } else {
    return (
      <div className=" h-screen overflow-y-scroll pb-44">
        <div className="px-4 md:px-8 lg:px-16   ">
          <div className="flex justify-between  items-center space-y-4 md:space-y-0">
            <Breadcrumb name={data?.color_name} />
            <RenderMenuBar
              setCopy={setCopy}
              isApprove={data?.status === "Approve"}
              isApiPublic={data?.type === "PUBLIC"}
              isNoAction={data?.status === ""}
              isPrivate={data?.type === "PRIVATE"}
              isMine={isMine}
              // copy={copy}
              showEditDone={showEditDone}
              setOpen={setOpen}
              onSubmit={onSubmit}
              index={index}
              open={open}
              id={data?._id}
              edit={edit}
              setEdit={setEdit}
            />
          </div>

          <RenderDetailSession
            image={data?.color_image}
            successTexts={successTexts}
            TitleSuccess={TitleSuccess}
          />
          <div className="  max-w-6xl mx-auto mt-4 md:mt-0">
            <RenderRemarkComponent remark={data?.remark} />
            {!size && (
              <FormulaColorTable
                selectedUnit={selectedUnit}
                setSelectedUnit={setSelectedUnit}
                finishcolors={finishcolors}
                finishColumns={finishColumns}
                data={data}
                setQuantity={setQuantity}
                quantity={quantity}
              />
            )}
          </div>

          <RenderMunuBarMobile
            isMine={isMine}
            // copy={copy}
            showEditDone={showEditDone}
            setOpen={setOpen}
            onSubmit={onSubmit}
            index={index}
            open={open}
          />
        </div>
        <div className="max-w-6xl mx-auto mt-4 md:mt-0 px-4 md:px-8 lg:px-16 ">
          <RenderCalculateCarPart
            quantity={quantity}
            data={finishcolors}
            selectedUnit={selectedUnit}
            setSelectedUnit={setSelectedUnit}
            finishColumns={finishColumns}
            setQuantity={setQuantity}
            size={size}
            setSize={setSize}
          />
        </div>
      </div>
    );
  }
};
