import React, { memo, useState, useEffect } from "react";
import LezzonDropdown from "../../components/LezzonDropdown";
import { CelesStandardPopUp } from "../../components/Popup/CelesStandardPopUp";
import api from "../../api";
// import CSVReader from "react-csv-reader";
import _ from "lodash";
import swalAlert from "../../utils/swalAlert";
import useToastify from "../../hooks/useToastify";
import useSweetAlert from "../../hooks/useSweetAlert";

const Model = () => {
  const [currentSystemCodeForInput, setCurrentSystemCodeForInput] =
    useState("");

  const [manufacturer, setManufacturer] = useState([
    "BMW",
    "HONDA",
    "ISUZU",
    "JAGUAR",
  ]);
  const [dropDownManu, setDropDownManu] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState({});
  const [modelInput, setModelInput] = useState("");
  const [models, setModels] = useState([]);

  const [loading, setLoading] = useState("");
  const { notifySuccess, notifyError } = useToastify();
  const { confirm } = useSweetAlert();

  useEffect(() => {
    LoadModel();
  }, []);

  useEffect(() => {
    LoadShape();
  }, [selectedManufacturer]);

  const LoadModel = async () => {
    setLoading(true);
    try {
      //ถ้าลูกค้าเพิ่มแล้วมันไม่ขึ้นลองเพิ่ม limit ดู
      const { data } = await api.get(`/models?limit=3000`);
      const data_table = data?.models?.map((row) => {
        return {
          menufacturer: row?.menufacturer,
          id: row._id,
        };
      });
      const data_dropdown = data?.models?.map((row) => {
        return {
          label: row?.menufacturer,
          value: row._id,
        };
      });

      setManufacturer(data_table);
      setDropDownManu(data_dropdown);
    } catch (error) {
      console.error("ERROR TO LOAD  MANUFACTURER :", error);
    } finally {
      setLoading(false);
    }
  };

  const LoadShape = async () => {
    if (selectedManufacturer) {
      setLoading(true);
      try {
        const { data } = await api.get(
          //ถ้าลูกค้าเพิ่มแล้วมันไม่ขึ้นลองเพิ่ม limit ดู
          `/shapes?limit=10000&menufacturer=${selectedManufacturer.label}`
        );
        const data_table = data?.shapes?.map((row) => {
          return {
            model: row?.model,
            menufacturer: row?.menufacturer,
            id: row._id,
          };
        });
        setModelInput("");
        setModels(data_table.reverse());
      } catch (error) {
        console.error("ERROR TO LOAD SHAPE :", error);
      } finally {
        setLoading(false);
      }
    } else {
      setModels([]);
    }
  };

  const onClickCreateManufacturer = async () => {
    if (currentSystemCodeForInput) {
      setLoading(true);
      try {
        await api.post(`/models`, {
          menufacturer: currentSystemCodeForInput,
        });
        setCurrentSystemCodeForInput("");
        LoadModel();
        notifySuccess();
      } catch (error) {
        console.error("ERROR TO DELETE :", error);
      } finally {
        setLoading(false);
      }
    } else {
      await swalAlert.Fail({ text: "Please fill in all the required information." });
    }
  };

  const onClickCreateModel = async () => {
    if (modelInput?.length > 0 && selectedManufacturer?.label !== undefined) {
      setLoading(true);
      try {
        await api.post(`/shapes`, {
          model: modelInput,
          menufacturer: selectedManufacturer?.label,
        });
        setModelInput("");
        LoadShape();
        notifySuccess();
      } catch (error) {
        console.error("ERROR TO CREATE MODEL :", error);
      } finally {
        setLoading(false);
      }
    } else {
      await swalAlert.Fail({ text: "Please fill in all the required information." });
    }
  };

  const clickDelete = async (id) => {
    await confirm({
      title: "Do you want to delete data?",
      callback: () => onClickToDeleteManufacturer(id),
    });
  };
  const onClickToDeleteManufacturer = async (id) => {
    setLoading(true);
    try {
      await api.delete(`/models/${id}`);
      LoadModel();
      notifySuccess();
    } catch (error) {
      console.error("ERROR TO DELETE MANUFACTURER :", error);
    } finally {
      setLoading(false);
    }
  };

  const clickDelete2 = async (id) => {
    await confirm({
      title: "Do you want to delete data",
      callback: () => onClickToDeleteModel(id),
    });
  };
  const onClickToDeleteModel = async (id) => {
    setLoading(true);
    try {
      await api.delete(`/shapes/${id}`);
      notifySuccess();
      LoadShape();
    } catch (error) {
      console.error("ERROR TO DELETE MODEL :", error);
    } finally {
      setLoading(false);
    }
  };

  ////////////////////////////////////////////////
  const ManufacturerCode = ({ id, name = "", index }) => {
    return (
      <div className="px-4 py-2 bg-white rounded-lg flex  justify-between items-center my-2">
        <div>{name}</div>
        <svg
          onClick={() => clickDelete(id)}
          className="w-6 h-6 text-textred cursor-pointer "
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          />
        </svg>
      </div>
    );
  };

  const ModalCode = ({ id, menufacturer, model }) => {
    return (
      <div className="px-4 py-2 bg-white rounded-lg flex  justify-between items-center my-2">
        <div>Model :{model}</div>
        <div>Manufacturer :{menufacturer}</div>
        <svg
          onClick={() => clickDelete2(id)}
          className="w-6 h-6 text-textred cursor-pointer "
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          />
        </svg>
      </div>
    );
  };

  // const importOptions = {
  //   header: true,
  //   dynamicTyping: true,
  //   skipEmptyLines: true,
  // };

  // const [data, setData] = useState("");

  // let result = [];

  // if (data) {
  //   data.map((row) => {
  //     const arr = Object.entries(row);

  //     arr.map((item) => {
  //       result.push({
  //         brand: item[0],
  //         name: item[1],
  //       });
  //     });
  //   });
  // }

  // const final = _.groupBy(result, "brand");

  // let keys = [];

  // if (data) {
  //   keys = Object.keys(data[0]);

  //   keys.slice(125, 126).forEach((key) => {
  //     const data_each_key = final[key].filter((r) => {
  //       return r.name;
  //     });
  //     console.log("data_each_key", data_each_key);
  //     // data_each_key.map(async (x) => {
  //     //   try {
  //     //     await api.post(`/shapes`, {
  //     //       model: x.name,
  //     //       menufacturer: x.brand,
  //     //     });
  //     //   } catch (error) {
  //     //     console.error("ERROR TO CREATE MODEL :", error);
  //     //   }
  //     // });
  //   });
  // }

  // console.log("selectedManufacturer",selectedManufacturer?.label)

  return (
    <div>
      {/* <CSVReader
        inputId="button-file"
        onFileLoaded={(data) => {
          setData(data);
        }}
        parserOptions={importOptions}
      /> */}
      <div>
        <div className="flex justify-between items-center mt-10">
          <div className="text-xl">Manufacturer</div>
        </div>
        <div className="mt-10">
          <div>Manufacturer</div>
          <div className="flex justify-start items-center space-x-4 mt-2">
            <input
              className="w-96  focus:outline-none bg-bgseccion   px-4 py-2 rounded-lg"
              // placeholder="B150"
              value={currentSystemCodeForInput}
              onChange={(e) => setCurrentSystemCodeForInput(e.target.value)}
            />
            <div
              className="flex justify-center items-center space-x-2  rounded-lg p-2 cursor-pointer text-white bg-waitingbuttoncolor"
              onClick={() => onClickCreateManufacturer()}
            >
              <svg
                className="w-6 h-6  "
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
              <div>Add</div>
            </div>
          </div>
        </div>
        <div className=" bg-bgseccion rounded-lg w-full p-4  h-80 overflow-y-scroll mt-6  ">
          {manufacturer?.map((systemCode, index) => {
            return (
              <div key={index}>
                <ManufacturerCode
                  name={systemCode.menufacturer}
                  index={index}
                  id={systemCode.id}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <div className="flex justify-between items-center mt-10">
          <div className="text-xl">Model</div>
        </div>
        <div className="mt-10 grid grid-cols-1 md:flex md:justify-between  items-end">
          <div className="">
            <div>Model</div>
            <div className="flex justify-start items-center space-x-4 mt-2">
              <input
                className="w-full lg:w-96  focus:outline-none bg-bgseccion   px-4 py-2 rounded-lg"
                placeholder="CENTURY"
                value={modelInput}
                onChange={(e) => setModelInput(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-4 md:mt-0 ">
            <div>
              <div>Manufacturer</div>
              <div className="flex   items-center space-x-4 mt-2">
                <LezzonDropdown
                  name=""
                  width=" w-full md:w-60 lg:w-96"
                  value={selectedManufacturer}
                  onChange={setSelectedManufacturer}
                  options={dropDownManu}
                  label={true}
                  //   value={selectedManufacture}
                  // onChange={(e) => setSelectedManufacture(e.target.value)}
                />
              </div>
            </div>
            <div
              className=" flex md:hidden justify-center items-center space-x-2  mt-4 md:mt-0 rounded-lg p-2 cursor-pointer text-white bg-waitingbuttoncolor"
              onClick={() => onClickCreateModel()}
            >
              <svg
                className="w-6 h-6  "
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
              <div>Add</div>
            </div>
          </div>
          <div
            className=" w-20 md:flex hidden justify-center items-center space-x-2  mt-4 md:mt-0 rounded-lg p-2 cursor-pointer text-white bg-waitingbuttoncolor"
            onClick={() => onClickCreateModel()}
          >
            <svg
              className="w-6 h-6  "
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
            <div>Add</div>
          </div>
        </div>
        <div className=" bg-bgseccion rounded-lg w-full p-4  h-80 overflow-y-scroll mt-6  ">
          {models?.map((model, index) => {
            return (
              <div key={index}>
                <ModalCode
                  menufacturer={model?.menufacturer}
                  model={model?.model}
                  index={index}
                  id={model?.id}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Model;
