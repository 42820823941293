export const BumbperFront = {
  name: "BumbperFront",
  S: 240,
  M: 260,
  L: 280,
  XL: 300,
};

export const FrontWingLeft = {
  name: "FrontWingLeft",
  S: 240,
  M: 260,
  L: 280,
  XL: 300,
};

export const FrontWingRight = {
  name: "FrontWingRight",
  S: 240,
  M: 260,
  L: 280,
  XL: 300,
};

export const BonnetFront = {
  name: "BonnetFront",
  S: 480,
  M: 500,
  L: 520,
  XL: 540,
};

export const RoofCenter = {
  name: "RoofCenter",
  S: 480,
  M: 500,
  L: 520,
  XL: 540,
};
export const FrontDoorLeft = {
  name: "FrontDoorLeft",
  S: 280,
  M: 300,
  L: 320,
  XL: 340,
};

export const FrontDoorRight = {
  name: "FrontDoorRight",
  S: 280,
  M: 300,
  L: 320,
  XL: 340,
};
export const BackDoorLeft = {
  name: "BackDoorLeft",
  S: 280,
  M: 300,
  L: 320,
  XL: 340,
};
export const BackDoorRight = {
  name: "BackDoorRight",
  S: 280,
  M: 300,
  L: 320,
  XL: 340,
};
export const BackWingLeft = {
  name: "BackWingLeft",
  S: 230,
  M: 250,
  L: 270,
  XL: 290,
};
export const BackWingRight = {
  name: "BackWingRight",
  S: 230,
  M: 250,
  L: 270,
  XL: 290,
};
export const BonnetRear = {
  name: "BonnetRear",
  S: 450,
  M: 470,
  L: 490,
  XL: 510,
};
export const BumbperRear = {
  name: "BumbperRear",
  S: 240,
  M: 260,
  L: 280,
  XL: 300,
};
export const SkirtFront = {
  name: "SkirtFront",
  S: 160,
  M: 180,
  L: 200,
  XL: 220,
};
export const SkirtLeft = {
  name: "SkirtLeft",
  S: 200,
  M: 220,
  L: 240,
  XL: 260,
};
export const SkirtRight = {
  name: "SkirtRight",
  S: 200,
  M: 220,
  L: 240,
  XL: 260,
};
export const SkirtRear = {
  name: "SkirtRear",
  S: 160,
  M: 180,
  L: 200,
  XL: 220,
};
