import React, { useState, useEffect } from "react";
import Select from "react-select";

const LezzonDropdown = ({
  name = "",
  width = "w-full",
  onChange,
  options,
  label = false,
  isLoading,
  value,
}) => {
  // const onSelect = (e) => {
  //   if (onChange != undefined) {
  //     if (label) {
  //       onChange(e);
  //     } else {
  //       onChange(e.value);
  //     }
  //   }
  // };

  // console.log("value: ", value);

  return (
    <div className={width}>
      <div className=" ">{name}</div>
      <Select
        isLoading={isLoading}
        className=" focus:outline-none   "
        options={options ? options : []}
        onChange={onChange}
        value={value ?? ""}
        // defaultValue={options ? options[0] : ""}
        // value={
        //   options &&
        //   options[
        //     options?.length > 0
        //       ? options?.findIndex((option) =>
        //           option?.value
        //             ? option?.value === value?.value
        //             : option === value
        //         )
        //       : 0
        //   ]
        // }
      />
    </div>
  );
};

export default LezzonDropdown;
