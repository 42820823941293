import React from "react";

function CardMain({
  code = "",
  updatestatus = "",
  name = "",
  type = "",
  status = "",
}) {
  const getStylebyStatus = (status = "Waiting") => {
    if (status === "Waiting")
      return " text-waitingbuttoncolor    rounded-full flex justify-center items-center  py-1  w-24  absolute top-2 left-2 ";
    return " text-approvebuttoncolor   rounded-full flex justify-center items-center  py-1  w-24  absolute top-2 left-2 ";
  };
  const getStylebyStatusUpdate = (updatestatus = "Approve") => {
    if (updatestatus === "Approve")
      return " bg-waitingbuttoncolor text-white rounded-full flex justify-center items-center   py-1  w-24  absolute top-2 right-2 ";
    return " bg-white text-textlightgray border rounded-full flex justify-center items-center   py-1  w-24  absolute top-2 right-2 ";
  };

  return (
    <a className=" cursor-pointer" href="/Admin/Info">
      <div>
        <div className="max-w-sm relative  text-textgray bg-bgseccion shadow-lg rounded-lg  ">
          <div className={getStylebyStatus(status)}>{status}</div>
          <div className="pt-10 pb-6 px-6">
            <div className="flex justify-between items-center">
              <h1 className="mt-4 font-semibold text-gray-800 ">
                Customer name : {name}
              </h1>
              <span className={getStylebyStatusUpdate(updatestatus)}>
                {updatestatus}
              </span>
            </div>
            <div className="text-textgray text-sm">
              <div className="flex items-center mt-4 ">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
                  />
                </svg>

                <h1 className="px-2 "> Color code : {code}</h1>
              </div>
              <div className="flex items-center mt-4 ">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                  />
                </svg>

                <h1 className="px-2 "> Type : {type}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}

export default CardMain;
