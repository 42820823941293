import React, { useEffect, useState } from "react";
import CardFinish from "../../components/Card/CardFinish";
import LezzonDropdown from "../../components/LezzonDropdown";
import { CelesStandardPopUp } from "../../components/Popup/CelesStandardPopUp";
import { CelesTableView } from "../../components/Table/CelesTableView";
export const FormulaColorTable = ({
  selectedUnit,
  setSelectedUnit,
  finishcolors,
  finishColumns,
  setQuantity,
  quantity,
  // hideInput = false,
}) => {
  const optionUnit = [
    { label: "Grams", value: "grams" },
    {
      label: "Milliliter",
      value: "milliliter",
    },
  ];

  useEffect(() => {
    setSelectedUnit(optionUnit[0]);
  }, []);

  return (
    <React.Fragment>
      <div className="my-2 ">Ingredients </div>
      <div className="flex justify-between">
        <div className="my-auto flex">
          <div className="text-textblue my-auto mx-4">Quantity :</div>

          <input
            onChange={(event) => setQuantity(event.target.value)}
            className="w-40 border rounded-md px-4 py-1 focus:outline-none"
            placeholder="0"
            // disabled={hideInput}
            value={quantity}
            type="number"
          />
        </div>
        <LezzonDropdown
          name=""
          width=" w-44 md:w-60 my-auto"
          options={optionUnit}
          value={selectedUnit}
          // value={optionUnit}
          onChange={setSelectedUnit}
        />
      </div>
      {/* <div className=" my-10 grid md:hidden  grid-cols-1 gap-4">
        {finishcolors?.map((finishcolor, index) => {
          return (
            <div key={index}>
              <CardFinish
                name={finishcolor.name}
                code={finishcolor.code}
                mill={finishcolor.mill}
                grams={finishcolor.grams}
                percent={finishcolor.percent}
                cum={finishcolor.cum}
              />
            </div>
          );
        })}
      </div> */}
      <div className="">
        <CelesTableView
          hoverbg=" hover:bg-sky-100"
          bgseccion=" bg-blue-50"
          bgcolor=" text-white bg-bgtableblue "
          isLoading={false}
          data={finishcolors}
          // rowAction={deleteIngradient}
          py={"2"}
          onClickUseIndex={true}
          columns={finishColumns}
        />
      </div>
    </React.Fragment>
  );
};
