import React, { memo, useState, useEffect } from "react";
import api from "../../api";
import useSweetAlert from "../../hooks/useSweetAlert";
import useToastify from "../../hooks/useToastify";
import swalAlert from "../../utils/swalAlert";

const ColorSystem = () => {
  const [currentSystemCodeForInput, setCurrentSystemCodeForInput] =
    useState("");
  const [descriptionForInput, setDescriptionForInput] = useState("");

  const [systemCodes, setSystemCodes] = useState(["B150", "B6", "B7", "B8"]);

  const [loading, setLoading] = useState("");
  const { notifySuccess, notifyError } = useToastify();
  const { confirm } = useSweetAlert();

  useEffect(() => {
    LoadColorSystem();
  }, []);

  const LoadColorSystem = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/systems`);
      const data_table = data?.systems?.map((row) => {
        return {
          system: row?.system_name,
          description: row.description,
          id: row._id,
        };
      });
      setSystemCodes(data_table);
    } catch (error) {
      console.error("ERROR TO LOAD COLOR SYSTEM  :", error);
    } finally {
      setLoading(false);
    }
  };

  const onClickCreateSystemColors = async () => {
    if (currentSystemCodeForInput && descriptionForInput) {
      setLoading(true);

      try {
        await api.post(`/systems`, {
          system_name: currentSystemCodeForInput,
          description: descriptionForInput,
        });
        setCurrentSystemCodeForInput("");
        setDescriptionForInput("");
        LoadColorSystem(); ///pull color again after delete in the table
        notifySuccess();
      } catch (error) {
        console.error("ERROR TO DELETE TINTER :", error);
      } finally {
        setLoading(false);
      }
    } else {
      await swalAlert.Fail({ text: "Please fill in all the required information." });
    }
  };

  const clickDelete = async (id) => {
    await confirm({
      title: "Do you want to delete the data?",
      callback: () => onClickToDeleteSystems(id),
    });
  };

  const onClickToDeleteSystems = async (id) => {
    try {
      await api.delete(`/systems/${id}`);
      LoadColorSystem();
      notifySuccess();
    } catch (error) {
      console.error("ERROR TO DELETE SYSTEM :", error);
    } finally {
      setLoading(false);
    }
  };

  const ColorSystemCode = ({ id, name = "", description = "", index }) => {
    return (
      <div className="px-4 py-2 bg-white rounded-lg flex flex-col md:flex-wrap  justify-between items-start my-2 ">
        <div className="">Color System : {name}</div>
        <div className=" ">Description : {description}</div>
        <div className=" ml-auto">
          <svg
            onClick={() => clickDelete(id)}
            className="w-6 h-6 text-textred cursor-pointer  "
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="flex justify-between items-center mt-10">
        <div className="text-xl">Color System</div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="mt-10">
          <div>Color System</div>
          <div className="flex justify-start items-center space-x-4 mt-2">
            <input
              className="w-full  focus:outline-none bg-bgseccion   px-4 py-2 rounded-lg"
              placeholder="B150"
              value={currentSystemCodeForInput}
              onChange={(e) => setCurrentSystemCodeForInput(e.target.value)}
            />
          </div>
        </div>
        <div className=" mt-0 md:mt-10">
          <div>Description</div>
          <div className="flex justify-start items-center space-x-4 mt-2">
            <input
              className="w-full  focus:outline-none bg-bgseccion   px-4 py-2 rounded-lg"
              placeholder="Description"
              value={descriptionForInput}
              onChange={(e) => setDescriptionForInput(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end items-end">
        <div
          className="flex justify-center  items-center space-x-2 mt-6 md:w-40  rounded-lg p-2 cursor-pointer text-white bg-waitingbuttoncolor"
          onClick={() => onClickCreateSystemColors()}
        >
          <svg
            className="w-6 h-6  "
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
          <div>Add</div>
        </div>
      </div>

      <div className=" bg-bgseccion rounded-lg w-full p-4  h-80 overflow-y-scroll mt-6  ">
        {systemCodes?.map((systemCode, index) => {
          return (
            <div key={index}>
              <ColorSystemCode
                name={systemCode.system}
                index={index}
                description={systemCode.description}
                id={systemCode.id}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ColorSystem;
