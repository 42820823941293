import { toast } from "react-toastify";

const useToastify = () => {
  const notifySuccess = (message) =>
    toast.success(message ?? "ดําเนินการสําเร็จ", {
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
    });
  const notifyError = (message) =>
    toast.error(message ?? "ดําเนินการไม่สำเร็จ", {
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
    });

  return { notifySuccess, notifyError };
};

export default useToastify;
