import axios from "axios";
import { Auth } from "aws-amplify";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:1337"
      : // "http://localhost:1337"
        "https://jol7hw6a19.execute-api.ap-southeast-1.amazonaws.com/dev",
  //   headers: {
  //     ["X-Api-Key"]: "DASHBOARD",
  //   },
});

// export const getJwtBearer = async () => {
//   const {
//     accessToken: { jwtToken },
//   } = await Auth.currentSession();
//   return `Bearer ${jwtToken}`;
// };

export default instance;
