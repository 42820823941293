import { useState, useEffect } from "react";

import { Auth } from "aws-amplify";
import SwalAlert from "../../utils/swalAlert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ErrorMessage } from "../../components/Field";

const ForgotPassword = () => {
  const router = useHistory();

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [checkForgotPassword, setCheckForgotPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");

  const onClickResetPassword = async () => {
    try {
      setLoading(true);

      if (!checkForgotPassword) {
        await Auth.forgotPassword(username);
        setCheckForgotPassword(true);
      } else {
        await Auth.forgotPasswordSubmit(username, code, password);
        await SwalAlert.Success({ text: "ทำการเปลี่ยนรหัสผ่านสำเร๊จ" });
        router.push("/Login");
      }
    } catch (error) {
      console.error("error signing up:", error);
      await SwalAlert.Fail({
        text:
          error?.response?.data?.message ||
          error?.message ||
          "Something went wrong",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      onClickResetPassword();
    }
  };

  return (
    <div className="max-w-lg md:w-full h-full mx-auto pt-60 pb-40 ">
      <div className=" shadow-md bg-white  bgshadow w-full h-full p-5 lg:py-20 rounded-2xl">
        <div className=" grid justify-items-center">
          <a onClick={() => router.push("/")}>
            <img src="../logo/logo.png" className=" w-92 h-auto pb-5 "></img>
          </a>
        </div>
        <div className=" lg:text-5xl text-4xl text-center text-bccpurple font-semibold p-5">
          Reset Password
        </div>

        <div className="md:w-full w-full mx-auto md:h-16 h-12 md:text-lg text-sm  md:px-0  grid justify-center">
          <div className=" text-bccgray font-light text-sm ">username</div>
          <div className="grid justify-items-center text-gray-700 ">
            <input
              onKeyPress={(e) => {
                handleKeypress(e);
              }}
              type="text"
              name={`username`}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full h-full focus:outline-none focus:ring focus:ring-purple-500 ring-1 rounded-md p-2 mx-10  text-sm font-light px-8 "
            />
          </div>
        </div>

        {checkForgotPassword && (
          <div className="">
            <div className=" md:w-full w-full mx-auto md:h-16 h-12 md:text-lg text-sm px-3 md:px-0 mt-4 grid justify-center">
              <div className="text-bccgray font-light text-sm ">OTP</div>
              <div className=" grid justify-items-center text-gray-700 ">
                <input
                  placeholder="ใส่รหัส 6 หลักที่ท่านได้จากอีเมล"
                  className="w-full h-full focus:outline-none focus:ring focus:ring-purple-500 ring-1 rounded-md p-2 mx-10  text-sm font-light px-8"
                  name="code"
                  onChange={(e) => setCode(e.target.value)}
                />{" "}
              </div>
              {errors.code && !code && (
                <ErrorMessage text={errors.code.message} />
              )}
            </div>

            <div className="">
              <div className="md:w-full w-full mx-auto md:h-16 h-12 md:text-lg text-sm px-3 md:px-0 mt-4 grid justify-center">
                <div className=" text-bccgray font-light text-sm ">
                  New password
                </div>
                <div className=" grid justify-items-center relative cursor-pointer">
                  <input
                    onKeyPress={(e) => {
                      handleKeypress(e);
                    }}
                    onChange={(e) => setPassword(e.target.value)}
                    name={`password`}
                    placeholder="เช่น ตัวเลข,ตัวอักษร 8 ตัวอักษรขึ้นไป"
                    value={password}
                    type={passwordShown ? "text" : "password"}
                    className="w-full h-full focus:outline-none focus:ring focus:ring-purple-500 ring-1 rounded-md p-2 mx-10 text-sm font-light px-8"
                  />
                  {passwordShown ? (
                    <svg
                      onClick={togglePassword}
                      xmlns="http://www.w3.org/2000/svg"
                      className="md:h-5 md:w-5 w-5 h-5 absolute md:top-3 md:right-5 right-5 top-3 text-gray-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                        clipRule="evenodd"
                      />
                      <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                    </svg>
                  ) : (
                    <svg
                      onClick={togglePassword}
                      xmlns="http://www.w3.org/2000/svg"
                      className="md:h-5 md:w-5 w-5 h-5 absolute md:top-3 md:right-5 right-5 top-3 text-gray-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path
                        fillRule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </div>
              </div>
              {errors.password && !password && (
                <ErrorMessage text={errors.password.message} />
              )}
            </div>
          </div>
        )}

        <div className=" grid justify-items-center mt-20 ">
          <button
            onClick={() => onClickResetPassword()}
            className="  bg-bccpurple hover:shadow-lg 00  lg:w-80 w-64 h-14 rounded-md grid items-center"
          >
            <div className=" text-lg font-semibold text-bccyellow hover:text-yellow-400 text-center cursor-pointer">
              ยืนยัน
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
