import CardMain from "../../components/Card/CardMain";
import React, { useEffect, memo, useState } from "react";
import { CelesTableView } from "../../components/Table/CelesTableView";
import { useHistory } from "react-router";
import api from "../../api";
import ConfirmModal from "../../components/Popup/ConfirmModal";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import { useNavCtx } from "../../App";

const Request = () => {
  const router = useHistory();
  const { setCurrentNav } = useNavCtx();

  const pathName = router.location.pathname;

  const [formulas, setFormulas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState("");
  const [userId, setUserId] = useState("");
  const [users, setUsers] = useState([]);
  const [openUser, setOpenUser] = useState(false);

  // console.log("userId", userId);

  const { user } = useSelector((state) => state.auth);

  const isAdmin = user?.role === "admin";

  useEffect(() => {
    setCurrentNav(pathName);
  }, [pathName]);

  useEffect(() => {
    LoadFormulas();
    LoadUserPending();
  }, []);

  const LoadFormulas = async () => {
    setLoading(true);
    try {
      if (isAdmin) {
        const { data } = await api.get("/formulas?status=Pending");

        const table_data = data?.formulas?.map((row) => {
          return {
            name: `${row?.maker?.firstname} ${row?.maker?.lastname}`,
            code: row?.color_code,
            type: row?.type,
            status: row?.status,
            updatestatus: row?.status,
            id: row?._id,
          };
        });

        setFormulas(table_data);
      } else {
        const { data } = await api.get(`/formulas?maker.id=${user?._id}`);

        const table_data = data?.formulas?.map((row) => {
          return {
            name: `${row?.maker?.firstname} ${row?.maker?.lastname}`,
            code: row?.color_code,
            type: row?.type,
            status: row?.status,
            updatestatus: row?.status,
            id: row?._id,
          };
        });

        setFormulas(table_data);
      }
    } catch (error) {
      console.error("Error to load formulas:", error);
    } finally {
      setLoading(false);
    }
  };

  const LoadUserPending = async () => {
    try {
      setLoading(true);
      const { data } = await api.get("/profiles?status=pending");
      setUsers(data.profiles);
    } catch (error) {
      console.error("ERROR TO LOAD PENDING : ", error);
    } finally {
      setLoading(false);
    }
  };

  const onClickOpenModal = async (id) => {
    try {
      setRowId(id);
      setOpen(true);
    } catch (error) {
      console.error("ERROR TO APPROVE FORMULA:", error);
    }
  };

  const onClickOpenModalUser = async (id) => {
    console.log("SAJDKLASJHDKLSAJDLKSAJDKLSAJKL", id);
    try {
      setUserId(id);
      setOpenUser(true);
    } catch (error) {
      console.error("ERROR TO APPROVE FORMULA:", error);
    }
  };

  const HookColumnsClassName = (status) => {
    if (status === "Approve") {
      return "bg-white border   text-textlightgray rounded-full flex justify-center items-center   py-1  w-24";
    } else if (status === "Cancled") {
      return "bg-white-500 border   text-red-500 rounded-full flex justify-center items-center   py-1  w-24";
    } else if (status === "Pending") {
      return "bg-waitingbuttoncolor text-white rounded-full flex justify-center items-center   py-1  w-24";
    } else {
      return "";
    }
  };

  const HookStatusClassName = (status) => {
    if (status === "Approve") {
      return " text-approvebuttoncolor";
    } else if (status === "Cancled") {
      return " text-red-500";
    } else if (status === "Pending") {
      return " text-waitingbuttoncolor  ";
    } else {
      return "";
    }
  };

  const columnsAdmin = [
    { Header: "Customer name", accessor: "name" },
    { Header: "Colour code ", accessor: "code" },
    { Header: "Type", accessor: "type" },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        return (
          <button className={HookStatusClassName(row.original.status)}>
            {row.original.status}
          </button>
        );
      },
    },
    {
      Header: "Update status",
      accessor: "updatestatus",
      Cell: ({ row }) => {
        if (row.original.status === "Pending") {
          return (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onClickOpenModal(row.original.id);
              }}
              className={HookColumnsClassName(row.original.status)}
            >
              {row.original.status}
            </button>
          );
        } else {
          return (
            // <div className={HookColumnsClassName(row.original.status)}>
            //   {row.original.status}
            // </div>
            <div></div>
          );
        }
      },
    },
  ];

  const columnsProfile = [
    { Header: "First name", accessor: "firstname" },
    { Header: "Last name", accessor: "lastname" },
    {
      Header: "Date",
      accessor: "date",
      Cell: ({ row }) => {
        return (
          <div className="">
            {new Date(row.original.createdAt).toLocaleDateString("th-TH")}
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        return (
          <button
            onClick={(e) => {
              e.stopPropagation();
              onClickOpenModalUser(row.original._id);
            }}
            className="bg-waitingbuttoncolor text-white rounded-full flex justify-center items-center   py-1  w-24 mx-auto"
          >
            Pending
          </button>
        );
      },
    },
  ];

  const columnsUser = [
    { Header: "Customer name", accessor: "name" },
    { Header: "Colour code ", accessor: "code" },
    { Header: "Type", accessor: "type" },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        return (
          <button className={HookStatusClassName(row.original.status)}>
            {row.original.status}
          </button>
        );
      },
    },
  ];

  const onPress = (row) => {
    router.push(`/Color/${row?.original?.id}`);
  };

  const onPressUsers = (row) => {
    router.push(`/User/${row?.original?._id}`);
  };

  const onClickApprove = async (id) => {
    setLoading(true);
    try {
      await api.put(`/formulas/${id}`, {
        status: "Approve",
        type: "PUBLIC",
      });
      LoadFormulas();
      setOpen(false);
    } catch (error) {
      console.error("ERROR TO APPROVE FORMULA:", error);
    } finally {
      setLoading(false);
    }
  };

  const onClickReject = async (id) => {
    setLoading(true);
    try {
      await api.put(`/formulas/${id}`, {
        status: "Canceled",
      });
      LoadFormulas();
      setOpen(false);
    } catch (error) {
      console.error("ERROR TO APPROVE FORMULA:", error);
    } finally {
      setLoading(false);
    }
  };

  const onClickApproveUser = async (id) => {
    setLoading(true);
    try {
      await api.put(`/profiles/${id}`, {
        status: "active",
      });
      LoadUserPending();
      setOpenUser(false);
    } catch (error) {
      console.error("ERROR TO APPROVE USER:", error);
    } finally {
      setLoading(false);
    }
  };

  const onClickRejectUser = async (id) => {
    setLoading(true);
    try {
      await api.put(`/profiles/${id}`, {
        status: "inactive",
      });
      LoadUserPending();
      setOpenUser(false);
    } catch (error) {
      console.error("ERROR TO APPROVE USER:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading />;

  return (
    <div className="px-4 md:px-8 lg:px-16  bg-white flex  flex-col justify-center sm:items-center items-start ">
      <div >
        <div className="text-xl mb-4">Approve Colours</div>
        <CelesTableView
          isLoading={false}
          data={formulas}
          rowAction={onPress}
          py={"2"}
          columns={isAdmin ? columnsAdmin : columnsUser}
        />
        <ConfirmModal
          title="Approve Colours  "
          id={rowId}
          open={open}
          setOpen={setOpen}
          onClickYes={onClickApprove}
          onClickNo={onClickReject}
          bottonNo="Reject"
          bottonYes="Approve"
          description="Do you want approval?"
        />
        {isAdmin && (
          <React.Fragment>
            <div className="text-xl mb-4">Approve Users</div>
            <CelesTableView
              isLoading={false}
              data={users}
              rowAction={onPressUsers}
              py={"2"}
              columns={columnsProfile}
            />
            <ConfirmModal
              title="Approve"
              id={userId}
              open={openUser}
              setOpen={setOpenUser}
              onClickYes={onClickApproveUser}
              onClickNo={onClickRejectUser}
              bottonNo="Reject"
              bottonYes="Approve"
              description="คุณต้องการอนุมัติบุคคลนี้หรือไม่"
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
export default Request;
