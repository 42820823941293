import React from "react";

function CardFinish({
  code = "",
  mill = "",
  name = "",
  grams = "",
  percent = "",
  cum = "",
}) {
  const getStylebyStatus = (status = "Waiting") => {
    if (status === "Waiting")
      return " text-waitingbuttoncolor    rounded-full flex justify-center items-center  py-1  w-24  absolute top-2 left-2 ";
    return " text-approvebuttoncolor   rounded-full flex justify-center items-center  py-1  w-24  absolute top-2 left-2 ";
  };
  const getStylebyStatusUpdate = (updatestatus = "Approve") => {
    if (updatestatus === "Approve")
      return " bg-waitingbuttoncolor text-white rounded-full flex justify-center items-center   py-1  w-24  absolute top-2 right-2 ";
    return " bg-white text-textlightgray border rounded-full flex justify-center items-center   py-1  w-24  absolute top-2 right-2 ";
  };

  return (
    <div>
      <div className="max-w-sm relative  text-textgray bg-bgseccion shadow-lg rounded-lg  ">
        {/* <div className={getStylebyStatus(status)}>{status}</div> */}
        <div className="pt-4 pb-6 px-6">
          <div className="flex justify-between items-center">
            <h1 className="mt-4 font-semibold text-gray-800 ">
              Tinter Code : {code}
            </h1>
          </div>

          <div className="text-textgray text-sm">
            <div className="flex items-center mt-4 ">
              <h1 className="  "> Tinter Name : {name}</h1>
            </div>
            <div className="flex items-center mt-4 ">
              <h1 className=" "> Milliliter : {mill}</h1>
            </div>
            <div className="flex items-center mt-4 ">
              <h1 className=" "> Weight(grams) : {grams}</h1>
            </div>
            <div className="flex items-center mt-4 ">
              <h1 className=" "> % : {percent}</h1>
            </div>
            <div className="flex items-center mt-4 ">
              <h1 className=" "> Weight(cum.) : {cum}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardFinish;
