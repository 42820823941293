import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function CardColor({
  code = "",
  name = "",
  id = "",
  deleteColor,
  manufacturer="",
  manufacturingYear="",
  type="",
  image,
  maker = "",
  radio="",
  updatedAt,
}) {
  const router = useHistory();
  const { user } = useSelector((state) => state.auth);


  return (
    <div onClick={() => router.push(`/Color/${id}`)}>
      <div className="w-full relative   bg-white border shadow-md cursor-pointer  ">
        <div className="text-sm absolute top-0 right-0   pr-2 items-center px-2  text-white  flex justify-end  mr-1 mt-1  bg-lightBlue-600 ml-12 ">
          {new Date(updatedAt).toLocaleDateString("en-GB")}
        </div>
        <div className="flex ">
          {image ? (
            <img className={"w-32 h-32 "} src={image} />
          ) : (
            <div className="w-32 h-32 bg-red-500"></div>
          )}
          <div className="mt-4 mx-4 flex flex-col justify-between  ">
            <div className="break-all">
              <div className="line-clamp-1 h-6 text-lg mt-2  ">{code}</div>
              <div className="line-clamp-1 h-6 text-textgray text-sm mt-1">
                {name}
              </div>
              <div className="line-clamp-1 h-5 text-textgray text-sm ">
                {manufacturer}
              </div>
              <div className="line-clamp-1 h-5 text-textgray text-sm ">
                {manufacturingYear}
              </div>
            </div>
            <div>
              {type !== "PUBLIC" && <div className="line-clamp-1 text-textblue text-sm ">
                maker : {maker}
              </div>}
            </div>
          </div>
          <div className=" w-1/4 h-full absolute top-0 right-0 flex justify-center items-center ">
            {user.role === "admin" && radio === "PUBLIC" && <button onClick={(e)=>{deleteColor(e, id)}}>
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.8662 6.15407C2.29365 6.16442 2.63173 6.5171 2.62131 6.94181L1.84735 6.92308L1.07338 6.90434C1.0838 6.47963 1.43876 6.14373 1.8662 6.15407ZM1.84735 6.92308C1.07338 6.90434 1.07338 6.90434 1.07338 6.90434L1.07235 6.94807L1.06956 7.07238C1.06721 7.18044 1.06396 7.33725 1.06034 7.53284C1.05312 7.92395 1.04446 8.47047 1.03868 9.09247C1.02716 10.333 1.02698 11.8865 1.07371 13.1062C1.12484 14.4402 1.26247 16.1669 1.37603 17.4527C1.50429 18.9048 2.73177 20 4.18698 20H11.813C13.2682 20 14.4957 18.9048 14.624 17.4527C14.7375 16.1669 14.8752 14.4402 14.9263 13.1062C14.973 11.8865 14.9728 10.333 14.9613 9.09247C14.9555 8.47047 14.9469 7.92395 14.9397 7.53284C14.936 7.33725 14.9328 7.18044 14.9304 7.07238L14.9277 6.94807L14.9266 6.90515C14.9162 6.48044 14.5612 6.14373 14.1338 6.15407C13.7063 6.16442 13.3683 6.5171 13.3787 6.94181L13.8719 6.92987C13.3787 6.94181 13.3787 6.94181 13.3787 6.94181L13.3797 6.98354L13.3824 7.10565C13.3847 7.2122 13.388 7.36732 13.3915 7.56106C13.3987 7.94862 13.4073 8.49035 13.413 9.10666C13.4245 10.3427 13.4243 11.8661 13.379 13.0477C13.3295 14.3412 13.1948 16.0358 13.0815 17.3182C13.0245 17.9641 12.4774 18.4615 11.813 18.4615H4.18698C3.52258 18.4615 2.97554 17.9641 2.91849 17.3182C2.80522 16.0358 2.67055 14.3412 2.62098 13.0477C2.5757 11.8661 2.57552 10.3427 2.587 9.10666C2.59273 8.49035 2.60132 7.94862 2.60847 7.56106C2.61205 7.36732 2.61527 7.2122 2.61759 7.10565L2.62032 6.98354L2.62131 6.94181L1.84735 6.92308Z"
                  fill="#C41F30"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 3.84615C0 3.42132 0.346618 3.07692 0.774194 3.07692H15.2258C15.6534 3.07692 16 3.42132 16 3.84615C16 4.27099 15.6534 4.61539 15.2258 4.61539H0.774194C0.346618 4.61539 0 4.27099 0 3.84615Z"
                  fill="#C41F30"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.25655 1.49321C4.7499 0.574111 5.71292 0 6.76129 0H9.23871C10.2871 0 11.2501 0.57411 11.7435 1.49321L12.8121 3.48416C13.0134 3.85902 12.8706 4.32497 12.4934 4.52489C12.1161 4.72481 11.6471 4.583 11.4459 4.20815L10.3772 2.21719C10.153 1.79942 9.71524 1.53846 9.23871 1.53846H6.76129C6.28476 1.53846 5.84702 1.79942 5.62277 2.21719L4.55408 4.20815C4.35287 4.583 3.88391 4.72481 3.50664 4.52489C3.12937 4.32497 2.98664 3.85902 3.18786 3.48416L4.25655 1.49321Z"
                  fill="#C41F30"
                />
              </svg>
            </button>}
            {user.role === "admin" && radio === "PRIVATE" && <button onClick={(e)=>{deleteColor(e, id)}}>
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.8662 6.15407C2.29365 6.16442 2.63173 6.5171 2.62131 6.94181L1.84735 6.92308L1.07338 6.90434C1.0838 6.47963 1.43876 6.14373 1.8662 6.15407ZM1.84735 6.92308C1.07338 6.90434 1.07338 6.90434 1.07338 6.90434L1.07235 6.94807L1.06956 7.07238C1.06721 7.18044 1.06396 7.33725 1.06034 7.53284C1.05312 7.92395 1.04446 8.47047 1.03868 9.09247C1.02716 10.333 1.02698 11.8865 1.07371 13.1062C1.12484 14.4402 1.26247 16.1669 1.37603 17.4527C1.50429 18.9048 2.73177 20 4.18698 20H11.813C13.2682 20 14.4957 18.9048 14.624 17.4527C14.7375 16.1669 14.8752 14.4402 14.9263 13.1062C14.973 11.8865 14.9728 10.333 14.9613 9.09247C14.9555 8.47047 14.9469 7.92395 14.9397 7.53284C14.936 7.33725 14.9328 7.18044 14.9304 7.07238L14.9277 6.94807L14.9266 6.90515C14.9162 6.48044 14.5612 6.14373 14.1338 6.15407C13.7063 6.16442 13.3683 6.5171 13.3787 6.94181L13.8719 6.92987C13.3787 6.94181 13.3787 6.94181 13.3787 6.94181L13.3797 6.98354L13.3824 7.10565C13.3847 7.2122 13.388 7.36732 13.3915 7.56106C13.3987 7.94862 13.4073 8.49035 13.413 9.10666C13.4245 10.3427 13.4243 11.8661 13.379 13.0477C13.3295 14.3412 13.1948 16.0358 13.0815 17.3182C13.0245 17.9641 12.4774 18.4615 11.813 18.4615H4.18698C3.52258 18.4615 2.97554 17.9641 2.91849 17.3182C2.80522 16.0358 2.67055 14.3412 2.62098 13.0477C2.5757 11.8661 2.57552 10.3427 2.587 9.10666C2.59273 8.49035 2.60132 7.94862 2.60847 7.56106C2.61205 7.36732 2.61527 7.2122 2.61759 7.10565L2.62032 6.98354L2.62131 6.94181L1.84735 6.92308Z"
                  fill="#C41F30"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 3.84615C0 3.42132 0.346618 3.07692 0.774194 3.07692H15.2258C15.6534 3.07692 16 3.42132 16 3.84615C16 4.27099 15.6534 4.61539 15.2258 4.61539H0.774194C0.346618 4.61539 0 4.27099 0 3.84615Z"
                  fill="#C41F30"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.25655 1.49321C4.7499 0.574111 5.71292 0 6.76129 0H9.23871C10.2871 0 11.2501 0.57411 11.7435 1.49321L12.8121 3.48416C13.0134 3.85902 12.8706 4.32497 12.4934 4.52489C12.1161 4.72481 11.6471 4.583 11.4459 4.20815L10.3772 2.21719C10.153 1.79942 9.71524 1.53846 9.23871 1.53846H6.76129C6.28476 1.53846 5.84702 1.79942 5.62277 2.21719L4.55408 4.20815C4.35287 4.583 3.88391 4.72481 3.50664 4.52489C3.12937 4.32497 2.98664 3.85902 3.18786 3.48416L4.25655 1.49321Z"
                  fill="#C41F30"
                />
              </svg>
            </button>}
            {user.role === "user" && radio === "PRIVATE" && <button onClick={(e)=>{deleteColor(e, id)}}>
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.8662 6.15407C2.29365 6.16442 2.63173 6.5171 2.62131 6.94181L1.84735 6.92308L1.07338 6.90434C1.0838 6.47963 1.43876 6.14373 1.8662 6.15407ZM1.84735 6.92308C1.07338 6.90434 1.07338 6.90434 1.07338 6.90434L1.07235 6.94807L1.06956 7.07238C1.06721 7.18044 1.06396 7.33725 1.06034 7.53284C1.05312 7.92395 1.04446 8.47047 1.03868 9.09247C1.02716 10.333 1.02698 11.8865 1.07371 13.1062C1.12484 14.4402 1.26247 16.1669 1.37603 17.4527C1.50429 18.9048 2.73177 20 4.18698 20H11.813C13.2682 20 14.4957 18.9048 14.624 17.4527C14.7375 16.1669 14.8752 14.4402 14.9263 13.1062C14.973 11.8865 14.9728 10.333 14.9613 9.09247C14.9555 8.47047 14.9469 7.92395 14.9397 7.53284C14.936 7.33725 14.9328 7.18044 14.9304 7.07238L14.9277 6.94807L14.9266 6.90515C14.9162 6.48044 14.5612 6.14373 14.1338 6.15407C13.7063 6.16442 13.3683 6.5171 13.3787 6.94181L13.8719 6.92987C13.3787 6.94181 13.3787 6.94181 13.3787 6.94181L13.3797 6.98354L13.3824 7.10565C13.3847 7.2122 13.388 7.36732 13.3915 7.56106C13.3987 7.94862 13.4073 8.49035 13.413 9.10666C13.4245 10.3427 13.4243 11.8661 13.379 13.0477C13.3295 14.3412 13.1948 16.0358 13.0815 17.3182C13.0245 17.9641 12.4774 18.4615 11.813 18.4615H4.18698C3.52258 18.4615 2.97554 17.9641 2.91849 17.3182C2.80522 16.0358 2.67055 14.3412 2.62098 13.0477C2.5757 11.8661 2.57552 10.3427 2.587 9.10666C2.59273 8.49035 2.60132 7.94862 2.60847 7.56106C2.61205 7.36732 2.61527 7.2122 2.61759 7.10565L2.62032 6.98354L2.62131 6.94181L1.84735 6.92308Z"
                  fill="#C41F30"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 3.84615C0 3.42132 0.346618 3.07692 0.774194 3.07692H15.2258C15.6534 3.07692 16 3.42132 16 3.84615C16 4.27099 15.6534 4.61539 15.2258 4.61539H0.774194C0.346618 4.61539 0 4.27099 0 3.84615Z"
                  fill="#C41F30"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.25655 1.49321C4.7499 0.574111 5.71292 0 6.76129 0H9.23871C10.2871 0 11.2501 0.57411 11.7435 1.49321L12.8121 3.48416C13.0134 3.85902 12.8706 4.32497 12.4934 4.52489C12.1161 4.72481 11.6471 4.583 11.4459 4.20815L10.3772 2.21719C10.153 1.79942 9.71524 1.53846 9.23871 1.53846H6.76129C6.28476 1.53846 5.84702 1.79942 5.62277 2.21719L4.55408 4.20815C4.35287 4.583 3.88391 4.72481 3.50664 4.52489C3.12937 4.32497 2.98664 3.85902 3.18786 3.48416L4.25655 1.49321Z"
                  fill="#C41F30"
                />
              </svg>
            </button>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardColor;
