import { FieldDropdown, FieldText } from "../../components/Field";
import React, {
  memo,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";

import api from "../../api";
import {
  OptionCountry,
  OptionRegion,
  OptionYear,
} from "../../utils/optionDropdown";

export const ColorController = ({
  form,
  setField,
  className = "w-full px-20 grid grid-cols-2 gap-4 md:gap-10 md:grid-cols-2 lg:grid-cols-3 mt-10",
}) => {
  const [loading, setLoading] = useState(false);
  const [systemCodes, setSystemCodes] = useState([]);
  const [menufacturer, setMenufacturer] = useState([]);
  const [colorGroup, setColorGroup] = useState([]);
  const [model, setModel] = useState([]);
  const [searchedModel, setSearchedModel] = useState("");

  useEffect(() => {
    LoadColorSystem();
    LoadColorGroup();
    LoadModel();
  }, []);

  useEffect(() => {
    if (form?.manufacturer) {
      LoadShape();
    } else {
      setModel([]);
    }
  }, [form?.manufacturer]);

  const LoadColorSystem = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/systems`);
      const data_table = data?.systems?.map((row) => {
        return {
          label: row?.system_name,
          value: row._id,
        };
      });
      setSystemCodes(data_table);
    } catch (error) {
      console.error("ERROR TO LOAD COLOR SYSTEM  :", error);
    } finally {
      setLoading(false);
    }
  };
  const LoadColorGroup = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/groups`);
      const data_table = data?.groups?.map((row) => {
        return {
          label: row?.group_name,
          value: row._id,
        };
      });
      setColorGroup(data_table);
    } catch (error) {
      console.error("ERROR TO LOAD COLOR GROUP  :", error);
    } finally {
      setLoading(false);
    }
  };

  const LoadModel = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/models?limit=1000`);
      const data_table = data?.models?.map((row) => {
        return {
          label: row?.menufacturer,
          value: row._id,
        };
      });
      setMenufacturer(data_table);
    } catch (error) {
      console.error("ERROR TO LOAD  MANUFACTURER :", error);
    } finally {
      setLoading(false);
    }
  };

  const onInputChangeModel = (event) => {
    setSearchedModel(event);
  };

  // const LoadShape = async () => {
  //   setLoading(true);
  //   try {
  //     const { data } = await api.get(`/shapes?search=${searchedModel}`);
  //     const data_table = data?.shapes?.map((row) => {
  //       return {
  //         label: row?.model,
  //         value: row._id,
  //       };
  //     });
  //     setModel(data_table);
  //   } catch (error) {
  //     console.error("ERROR TO LOAD SHAPE :", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  const LoadShape = async () => {
    setLoading(true);
    if (form?.manufacturer) {
      // console.log("IN");
      try {
        const { data } = await api.get(
          `/shapes?menufacturer=${form?.manufacturer?.label}`
        );

        const data_table = data?.shapes?.map((row) => {
          return {
            label: row?.model,
            value: row._id,
          };
        });
        setModel(data_table);
      } catch (error) {
        console.error("ERROR TO LOAD SHAPE :", error);
      } finally {
        setLoading(false);
      }
    } else {
      setModel([]);
    }
  };

  return (
    <div className={className}>
      <FieldText
        // clickSearch={clickSearch}
        setField={setField}
        label="Colour Code"
        name="color_code"
        value={form?.color_code}
      />
      <FieldText
        setField={setField}
        label="Colour Ref"
        name="color_ref"
        value={form?.color_ref}
      />
      <FieldText
        setField={setField}
        label="Colour Name"
        name="color_name"
        value={form?.color_name}
      />
      <FieldDropdown
        setField={setField}
        label="Colour System"
        name="color_system"
        value={form?.color_system}
        options={systemCodes}
      />
      <FieldDropdown
        setField={setField}
        label="Colour Group"
        name="color_group"
        value={form?.color_group}
        options={colorGroup}
      />
      <FieldDropdown
        setField={setField}
        label="Manufacturer"
        name="manufacturer"
        value={form?.manufacturer}
        options={menufacturer}
      />
      <FieldDropdown
        setField={setField}
        label="Model (Please enter Manufacturer first.)"
        name="model"
        value={form?.model}
        options={model}
        onInputChange={onInputChangeModel}
      />
      <FieldDropdown
        setField={setField}
        label="Country"
        name="country"
        value={form?.country}
        options={OptionCountry}
        defaultValue={OptionCountry[0]}
      />
      <FieldDropdown
        setField={setField}
        label="Region"
        name="region"
        value={form?.region}
        options={OptionRegion}
        defaultValue={OptionRegion[0]}
      />
      <FieldDropdown
        setField={setField}
        label="Year"
        name="year"
        value={form?.year}
        options={OptionYear}
        // defaultValue={optionYears[0]}
      />
    </div>
  );
};
