import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import api from "../../api";
import Loading from "../../components/Loading/Loading";

export const FieldShowText = ({ title, text }) => {
  return (
    <div className="flex justify-between px-2 py-4 border border-textblue">
      <div className="text-xl text-blue-500">{title} :</div>
      <div className="text-xl text-textblue my-auto mx-4"> {text}</div>
    </div>
  );
};

export const UserProfile = () => {
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const router = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (user?.role === "admin") {
      LoadUser();
      console.log("IN");
      return;
    } else {
      router.push("/");
    }
  }, [user]);

  const LoadUser = async () => {
    setLoading(true);
    const { data } = await api.get(`/profiles/${id}`);
    setProfile(data.profile);
    try {
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onClickApprove = async () => {
    try {
      await api.put(`/profiles/${id}`, {
        status: "active",
      });
      router.push("/");
    } catch (error) {
      console.error("ERROR TO APPROVE USER:", error);
    }
  };

  const onClickReject = async () => {
    try {
      await api.put(`/profiles/${id}`, {
        status: "inactive",
      });
      router.push("/");
    } catch (error) {
      console.error("ERROR TO REJECT USER:", error);
    }
  };

  if (loading) return <Loading />;

  return (
    <div className="p-10">
      <div className="flex justify-between">
        <button
          onClick={() => router.goBack()}
          className=" focus:outline-none rounded-md mt-4 md:mt-0  bg-textblue w-40 py-2 text-white text-lg flex justify-center items-center "
        >
          Back
        </button>
        {profile?.status === "pending" ? (
          <div className="grid grid-cols-2 gap-5">
            <button
              onClick={() => onClickApprove()}
              className=" focus:outline-none rounded-md mt-4 md:mt-0  bg-textblue w-40 py-2 text-white text-lg flex justify-center items-center "
            >
              Approve
            </button>
            <button
              onClick={() => onClickReject()}
              className=" focus:outline-none rounded-md mt-4 md:mt-0  bg-textblue w-40 py-2 text-white text-lg flex justify-center items-center "
            >
              Reject
            </button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div className="grid grid-cols-2 border border-textblue mt-8">
        {Object.keys(profile).length > 0 &&
          Object.keys(profile).map((key) => {
            return <FieldShowText title={key} text={profile[key]} />;
          })}
      </div>
    </div>
  );
};
