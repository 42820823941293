import { useHistory } from "react-router";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initAuth } from "../../redux/authSlice";
import { useNavCtx } from "../../App";

const Logout = ({ onClick }) => {
  return (
    <div
      onClick={(e) => onClick(e)}
      className="absolute right-14 w-40 bg-white top-16 p-2 rounded-md border"
    >
      <div className="  flex justify-center items-center  cursor-pointer hover:underline text-textblue">
        Logout
      </div>
    </div>
  );
};

const   NavBar = () => {
  const router = useHistory();
  const dispatch = useDispatch();
  const { currentNav } = useNavCtx();

  const { user } = useSelector((state) => state.auth);

  const isAdmin = user?.role === "admin";

  const routerAddColor = () => {
    router.push("/Color");
  };
  const routerToHome = () => {
    if (isAdmin) {
      router.push("/");
    } else {
      router.push("/Color");
    }
  };
  const routerToSetting = () => {
    router.push("/Setting");
  };
  const routerToRequest = () => {
    router.push("/Admin");
  };
  const routerToDashboard = () => {
    router.push("/Dashboard");
  };

  const [openLogout, setOpenLogout] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const onClickLogout = () => {
    setOpenLogout(!openLogout);
  };

  const onClickSignOut = () => {
    localStorage.clear();
    dispatch(initAuth());
    router.push("/Login");
  };

  const isApprove = currentNav === "/";
  const isDashboard = currentNav === "/Dashboard";
  const isPageAdmin = currentNav === "/Admin";
  const isColor = currentNav === "/Color";
  const isSettings = currentNav === "/Setting";

  const commonNavStyle =
    "text-textblue  cursor-pointer hover:bg-textblue hover:text-white px-4 py-2 rounded-lg";
  const activeNavStyle =
    "text-white bg-textblue cursor-pointer   px-4 py-2 rounded-lg";

  return (
    <div className="flex flex-col xl:flex-row py-6 px-4  md:px-8 lg:px-16 justify-between items-center shadow-md fixed w-full bg-white z-40 ">
      <div className="flex justify-center xl:justify-between items-center relative  w-full xl:w-auto  ">
        <img
          onClick={() => routerToHome()}
          className="w-auto h-10 cursor-pointer"
          src="/logo/logo.png"
        />
        <button
          className=" xl:hidden absolute top-1 right-0  "
          onClick={toggleDrawer}
        >
          <svg
            className="w-8 h-8 text-textblue"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
        <Drawer open={isOpen} onClose={toggleDrawer} direction="right">
          <div
            onClick={toggleDrawer}
            className=" xl:hidden flex flex-col justify-start items-start space-y-4 p-4"
          >
            {isAdmin && (
              <div
                className="text-textblue text-lg cursor-pointer hover:bg-textblue hover:text-white px-4 py-2 rounded-lg"
                onClick={routerToHome}
              >
                Approve
              </div>
            )}
            {isAdmin && (
              <div
                className="text-textblue text-lg cursor-pointer hover:bg-textblue hover:text-white px-4 py-2 rounded-lg"
                onClick={routerToDashboard}
              >
                Overview
              </div>
            )}
            {isAdmin && (
              <div
                className="text-textblue text-lg cursor-pointer hover:bg-textblue hover:text-white px-4 py-2 rounded-lg"
                onClick={routerToRequest}
              >
                Request Formular
              </div>
            )}
            <div
              className="text-textblue text-lg cursor-pointer hover:bg-textblue hover:text-white px-4 py-2 rounded-lg"
              onClick={routerAddColor}
            >
              Color Online
            </div>
            {isAdmin && (
              <div
                className="text-textblue text-lg cursor-pointer hover:bg-textblue hover:text-white px-4 py-2 rounded-lg"
                onClick={routerToSetting}
              >
                Setting
              </div>
            )}
            <div className="text-textblue text-lg cursor-pointer hover:bg-textblue hover:text-white px-4 py-2 rounded-lg">
              {user?.firstname} {user?.lastname}
            </div>
            <div
              onClick={() => onClickSignOut()}
              className="text-textred text-lg cursor-pointer hover:bg-textred hover:text-white px-4 py-2 rounded-lg"
            >
              Logout
            </div>
          </div>
        </Drawer>
      </div>

      {/* <div className="pt-2 relative mx-auto w-full  max-w-lg xl:max-w-sm text-gray-600">
        <input
          className="bg-gray-100 w-full  xl:w-96 py-2 px-4 rounded-full flex text-gray-400 focus:outline-none items-center  "
          type="search"
          name="search"
          placeholder="Search"
        />
      </div> */}

      <div className=" hidden xl:flex text-base  justify-center items-center xl:space-x-2">
        {isAdmin && (
          <div
            className={isApprove ? activeNavStyle : commonNavStyle}
            // className="text-textblue  cursor-pointer hover:bg-textblue hover:text-white px-4 py-2 rounded-lg"
            onClick={routerToHome}
          >
            Approve
          </div>
        )}
        {isAdmin && (
          <div
            className={isDashboard ? activeNavStyle : commonNavStyle}
            // className="text-textblue  cursor-pointer hover:bg-textblue hover:text-white px-4 py-2 rounded-lg"
            onClick={routerToDashboard}
          >
            Overview
          </div>
        )}
        {isAdmin && (
          <div
            className={isPageAdmin ? activeNavStyle : commonNavStyle}
            // className="text-textblue  cursor-pointer hover:bg-textblue hover:text-white px-4 py-2 rounded-lg"
            onClick={routerToRequest}
          >
            Request Formular
          </div>
        )}
        <div
          // isColor
          className={isColor ? activeNavStyle : commonNavStyle}
          // className="text-textblue  cursor-pointer hover:bg-textblue hover:text-white px-4 py-2 rounded-lg"
          onClick={() => routerAddColor()}
        >
          Color Online
        </div>
        {isAdmin && (
          <div
            // isSettings
            className={isSettings ? activeNavStyle : commonNavStyle}
            // className="text-textblue  cursor-pointer hover:bg-textblue hover:text-white px-4 py-2 rounded-lg"
            onClick={() => routerToSetting()}
          >
            Setting
          </div>
        )}
        <div
          onClick={onClickLogout}
          className="text-textblue  cursor-pointer hover:bg-textblue hover:text-white px-4 py-2 rounded-lg"
        >
          {user?.firstname} {user?.lastname}
        </div>

        {openLogout && <Logout onClick={() => onClickSignOut()} />}
      </div>
    </div>
  );
};
export default NavBar;
