import React, {
  memo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import Select from "react-select";
import api from "../../api";

import {
  AddUrlToFile,
  getPathUrl,
  resizeFile,
  UploadFileAws,
} from "../../Functions";
import { CelesTableView } from "../../components/Table/CelesTableView";
import LezzonDropdown from "../../components/LezzonDropdown";
import Loading from "../../components/Loading/Loading";
import { CelesStandardPopUp } from "../../components/Popup/CelesStandardPopUp";
import { useHistory } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { FieldText } from "../../components/Field";
import { ColorController } from "./ColorController";
import { useSelector } from "react-redux";
import swalAlert from "../../utils/swalAlert";
import { useNavCtx } from "../../App";
import useToastify from "../../hooks/useToastify";

const ColorCreate = ({ previous, setEdit = () => {}, showEditDone = () => {},  mode }) => {
  const router = useHistory();
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [ingredientAddColor, setIngredientAddColor] = useState(null);
  const [ingredaientWeight, setIngredaientWeight] = useState(0);
  const [addcolors, setAddcolors] = useState([]);
  const [oldImage, setOldImage] = useState("");
  const [colors, setColors] = useState([]);

  const [nameColor,setNameColor] = useState([]);

  const { notifySuccess, notifyError } = useToastify();

  const options_color_filter =
    colors?.length > 0 &&
    colors?.filter((o) => {
      const findIndex = addcolors?.findIndex((row) => {
        return row.code === o.label;
      });
      return findIndex === -1;
    });

  const index = 0;

  const { user } = useSelector((state) => state.auth);

  // const { setCurrentNav } = useNavCtx();
  // const pathName = router.location.pathname;

  // useEffect(() => {
  //   setCurrentNav(pathName);
  // }, [pathName]);

  useEffect(() => {
    if (previous) {
      setForm(previous);

      setAddcolors(previous?.colors);
      setOldImage(previous?.color_image);
    } else {
      return;
    }
  }, [previous]);

  useEffect(() => {
    LoadColors();
  }, []);

  const LoadColors = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/colors`);
      const option_colors = data?.colors.map((color) => {
        return {
          value: {
            label: color.color_name_th,
            name: color.color_name,
            code: color.color_code,
            density: color.density,
            weight: color.weight,
          },
          label: color.color_code,
        };
      });
      const colorname = data?.colors.map((color)=>{
        return color.color_code;
      })

      setColors(option_colors);
      setNameColor(colorname);
    } catch (error) {
      console.error("Error to load  :", error);
    } finally {
      setLoading(false);
    }
  };

  // console.log("colorname",nameColor.includes(ingredientAddColor.value.code));
  // console.log("ingredientAddColor",ingredientAddColor.value.code)  

  const setField = useCallback(
    (field) => (e) => {
      const value = e && e.target ? e.target.value : e;
      setForm((currentForm) => ({
        ...currentForm,
        [field]: value,
      }));
    },
    [setForm]
  );

  const onClickUpload = async ({ target: { files } }) => {
    const file = await files[0];
    const resize = await resizeFile(file);
    setFile(resize);
  };

  const submit = () => {
    setLoading(true);
    setTimeout(() => {
      showDone();
    }, 2000);
  };
  const showDone = () => {
    setLoading(false);
    setDone(true);
  };

  const checkColorCreate =
    form?.color_code && addcolors?.length > 0 && form?.color_name;

  //TaskColorCode//
  const onClickCreateTaskColorCode = async () => {
    if (checkColorCreate) {
      setLoading(true);
      const image_key = await UploadFileAws(file);
      try {
        await api.post(`/formulas`, {
          color_code: form?.color_code,
          color_ref: form?.color_ref,
          color_name: form?.color_name,
          color_system: form?.color_system,
          color_group: form?.color_group,
          manufacturer: form?.manufacturer,
          model: form?.model,
          country: form?.country,
          region: form?.region,
          year: form?.year,
          colors: addcolors,
          remark: form?.remark,
          color_image: image_key,
          maker: {
            firstname: user?.firstname,
            lastname: user?.lastname,
            id: user?._id,
          },
        });
        setFile(null);
        // setColorGroups();
        router.push(`/Color`);
        notifySuccess();
      } catch (error) {
        console.error("Error to create information :", error);
      } finally {
        setLoading(false);
      }
    } else {
      await swalAlert.Fail({ text: "Please fill in all the required information." });
    }
  };

  const onClickCopyTaskColorCode = async () => {
    if (checkColorCreate) {
      setLoading(true);
      const image_key = await UploadFileAws(file);
      try {
        await api.post(`/formulas`, {
          color_code: form?.color_code,
          color_ref: form?.color_ref,
          color_name: form?.color_name,
          color_system: form?.color_system,
          color_group: form?.color_group,
          manufacturer: form?.manufacturer,
          model: form?.model,
          country: form?.country,
          region: form?.region,
          year: form?.year,
          colors: addcolors,
          remark: form?.remark,
          color_image: image_key ? image_key : oldImage,
          maker: {
            firstname: user?.firstname,
            lastname: user?.lastname,
            id: user?._id,
          },
        });
        setFile(null);
        // setColorGroups();
        router.push(`/Color`);
        notifySuccess();
      } catch (error) {
        console.error("Error to create information :", error);
      } finally {
        setLoading(false);
      }
    } else {
      await swalAlert.Fail({ text: "โปรดกรอกข้อมูล" });
    }
  };

  const onCliclCancel = () => {

    if (previous) {
      showEditDone(false);
    } else {
      router.push(`/Color`);
    }
  };

  const onClickUpdateData = async (id) => {
    if (checkColorCreate) {
      setLoading(true);
      const image_key = await UploadFileAws(file);
      try {
        await api.put(`/formulas/${id}`, {
          color_code: form?.color_code,
          color_ref: form?.color_ref,
          color_name: form?.color_name,
          color_system: form?.color_system,
          color_group: form?.color_group,
          manufacturer: form?.manufacturer,
          model: form?.model,
          country: form?.country,
          region: form?.region,
          year: form?.year,
          colors: addcolors,
          remark: form?.remark,
          color_image: image_key,
          maker: {
            firstname: user?.firstname,
            lastname: user?.lastname,
            id: user?._id,
          },
          type: "PRIVATE",
        });
        setFile(null);
        // setColorGroups();
        router.push(`/Color`);
        notifySuccess();
      } catch (error) {
        console.error("Error to update information :", error);
      } finally {
        setLoading(false);
      }
    } else {
      await swalAlert.Fail({ text: "Please fill in all the required information." });
    }
  };

  //table//
  const columns = [
    { Header: "Tinter Code", accessor: "code" },
    { Header: "Tinter Name ", accessor: "name" },
    {
      Header: "Weight (Grams)",
      accessor: "gram",
      Cell: ({ row }) => {
        if (done)
          return (
            <div className=" flex   justify-between  space-x-2 md:space-x-6 items-center w-full">
              <div className="flex justify-center items-center mx-auto">
                {row.original.gram}
              </div>
            </div>
          );
        return (
          <div className=" flex   justify-between  space-x-2 md:space-x-6 items-center w-full">
            <div className="flex justify-center items-center mx-auto">
              {row.original.gram}
            </div>

            <svg
              rowAction={deleteIngradient}
              onClick={() => deleteIngradient(row.index)}
              className="w-6 h-6 text-textred cursor-pointer  "
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </div>
        );
      },
    },
  ];

  const addIngradient = () => {
    if (ingredaientWeight > 0 && nameColor.includes(ingredientAddColor?.value?.code)) {
      let temp = [...addcolors];
      temp?.push({
        code: ingredientAddColor?.value?.code,
        gram: ingredaientWeight,
        density: ingredientAddColor?.value?.density,
        name_th: ingredientAddColor?.label,
        name: ingredientAddColor?.value?.name,
        weight: ingredientAddColor?.value?.weight,
      });
      setAddcolors(temp);
      // const findIndex = options_color_filter?.findIndex((d) => {
      //   return d.label === ingredientAddColor.code;
      // });
      // setIngredientAddColor(options_color_filter[findIndex + 1]);
      setIngredientAddColor(null);
      setIngredaientWeight("");
    } else {
      swalAlert.Fail({ text: "โปรดกรอกข้อมูล" });
    }
  };

  const deleteIngradient = (index) => {
    let temp = [...addcolors];
    if (index > -1) {
      temp.splice(index, 1);
    }
    setAddcolors(temp);
  };

  const Breadcrumb = memo(({ name = "" }) => {
    return (
      <div className="flex md:justify-center items-center space-x-4 text-textlightgray">
        <div onClick={() => router.push(`/Color`)} className=" ">
          Color Online
        </div>
        <div className="text-textblue">
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
        <div
          onClick={() => router.push(`/Color/Create`)}
          className="text-textblue cursor-pointer hover:underline "
        >
          Create Colour
        </div>
      </div>
    );
  });

  const onSave = () => {
    if (previous && mode === "EDIT") {
      onClickUpdateData(previous?._id);
    } else if (previous && mode === "COPY") {
      onClickCopyTaskColorCode();
    } else {
      onClickCreateTaskColorCode();
    }
    // setOpen(true);
  };

  if (loading) return <Loading />;

  // if (done) return <ColorEdit />;

  return (
    <div className="px-4 md:px-8 lg:px-16 h-screen overflow-y-scroll pb-44">
      <div className="flex justify-between  items-center space-y-4 md:space-y-0">
        <Breadcrumb />
        <div className="space-x-4  justify-center items-center hidden md:flex">
          <button
            onClick={() => onSave()}
            // onClick={submit}
            className=" focus:outline-none bg-lightBlue-500 text-white rounded-md  flex  justify-center items-center w-24 py-1"
          >
            <CelesStandardPopUp
              hidden
              panel={
                <div className="relative w-96  rounded-xl h-32  ">
                  <div className="m-4 flex flex-col justify-center items-center  space-y-2">
                    <div className="text-xl ">
                      คุณยืนยันที่จะบันทึก ใช่ หรือ ไม่
                    </div>
                    <div className="flex justify-center items-center space-x-2">
                      <button
                        type="button"
                        className="w-24 my-2 inline-flex justify-center text-base rounded-md bg-waitingbuttoncolor text-white px-4 py-1 mx-4 font-medium  focus:outline-none"
                        onClick={submit}
                      >
                        ยืนยัน
                      </button>
                      <button
                        onClick={() => setOpen(false)}
                        className="w-24 my-2 inline-flex justify-center rounded-md  px-4 py-1 text-white text-base font-medium  bg-textlightgray focus:outline-none"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </div>
              }
              key={index}
              open={open}
              setOpen={setOpen}
            />
            Save
          </button>
          <button
            onClick={() => {
              onCliclCancel()
            }}
            className=" focus:outline-none   bg-textred text-white rounded-md  flex  justify-center items-center w-24 py-1"
          >
            Cancel
          </button>
        </div>
      </div>
      <div className="  bg-white   flex  flex-col lg:flex-row   justify-between  items-center  max-w-6xl mx-auto mt-4 md:mt-0  ">
        <div className=" flex flex-col w-full  max-w-sm  ">
          {/* <Status /> */}
          <label for="button-file ">
            {file ? (
              <div>
                <img
                  className="w-full h-60 object-cover rounded-md"
                  src={AddUrlToFile(file)}
                />
              </div>
            ) : (
              [
                oldImage ? (
                  <img
                    className="w-full h-60 object-cover rounded-md"
                    src={getPathUrl(oldImage)}
                  />
                ) : (
                  <div>
                    <div className=" border flex flex-col justify-center   items-center rounded-md text-waitingbuttoncolor border-waitingbuttoncolor    h-60 ">
                      {/* <FieldUploadImage label="Upload Image" /> */}
                      <svg
                        className="w-8 h-8 mb-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>{" "}
                    </div>
                  </div>
                ),
              ]
            )}
            <div>
              <div className="underline cursor-pointer mt-2 text-waitingbuttoncolor">
                <input
                  accept="image/jpeg,image/gif,image/png"
                  className=""
                  id="button-file"
                  type="file"
                  onChange={onClickUpload}
                />
              </div>
            </div>
          </label>
        </div>
        <ColorController
          form={form}
          setField={setField}
          className=" grid grid-cols-2 gap-4  mt-10"
        />
      </div>
      <div className="  max-w-6xl mx-auto mt-4 md:mt-0">
        <FieldText
          setField={setField}
          label="Remark"
          name="remark"
          value={form?.remark}
        />
        <div className="mt-4 ">Ingredients</div>
        <div>
          <CelesTableView
            hoverbg=" hover:bg-sky-100"
            bgseccion=" bg-blue-50"
            bgcolor=" text-white bg-bgtableblue "
            isLoading={false}
            data={addcolors}
            rowAction={deleteIngradient}
            py={"2"}
            onClickUseIndex={true}
            columns={columns}
          />
          <div className=" bg-waitingbuttoncolor  px-4 py-2  flex flex-row  items-center justify-between">
            <LezzonDropdown
              name=""
              width=" w-44 md:w-60"
              value={ingredientAddColor}
              onChange={setIngredientAddColor}
              options={options_color_filter}
            />
            <div className="flex justify-center items-center space-x-4">
              <input
                value={ingredaientWeight}
                onChange={(event) => setIngredaientWeight(event.target.value)}
                className=" w-20 md:w-40 px-4 py-2 focus:outline-none rounded-md"
                placeholder=" 0"
                type="number"
              ></input>
              <svg
                onClick={addIngradient}
                className="w-6 h-6 text-white border-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="space-x-4  justify-center items-center md:hidden flex mt-6">
          <button
            onClick={() => setOpen(true)}
            // onClick={submit}
            className=" focus:outline-none bg-lightBlue-500 text-white rounded-md  flex  justify-center items-center w-24 py-1"
          >
            <CelesStandardPopUp
              hidden
              panel={
                <div className="relative  w-96  rounded-xl h-32  ">
                  <div className="m-4 flex flex-col justify-center items-center  space-y-2">
                    <div className="text-xl ">
                      คุณยืนยันที่จะบันทึก ใช่ หรือ ไม่
                    </div>
                    <div className="flex justify-center items-center space-x-2">
                      <button
                        type="button"
                        className="w-24 my-2 inline-flex justify-center text-base rounded-md bg-waitingbuttoncolor text-white px-4 py-1 mx-4 font-medium  focus:outline-none"
                        onClick={submit}
                      >
                        ยืนยัน
                      </button>
                      <button
                        onClick={() => setOpen(false)}
                        className="w-24 my-2 inline-flex justify-center rounded-md  px-4 py-1 text-white text-base font-medium  bg-textlightgray focus:outline-none"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </div>
              }
              key={index}
              open={open}
              setOpen={setOpen}
            />
            Save
          </button>
          <button className=" focus:outline-none   bg-textred text-white rounded-md  flex  justify-center items-center w-24 py-1">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
export default ColorCreate;
