import React, { memo, useState, useEffect } from "react";

import { ColorEdit } from "../Color/ColorEdit";

export const RenderRemarkComponent = ({ remark }) => {
  return (
    <div>
      <div className="  max-w-6xl mx-auto mt-4 md:mt-0">
        <div className="mb-2 ">Remark</div>
        <div className="   text-textgray rounded-md w-full px-4 py-2 outline-none">
          {remark}
        </div>
      </div>
    </div>
  );
};

const ColorCreate = () => {
  return (
    <React.Fragment>
      <ColorEdit />
      {/* <RenderButoonSession /> */}
    </React.Fragment>
  );
};
export default ColorCreate;
