import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React, { createContext, useContext, useState } from "react";

//COMPONENTS
import NavBar from "./components/Layout/Navbar";

//PAGES
import Home from "./pages/Home/Home";
import ColorMain from "./pages/Color/ColorMain";
import { ColorEdit } from "./pages/Color/ColorEdit";
import SettingMain from "./pages/Setting/SettingMain";
import Login from "./pages/Login/Login";
import ColorCreate from "./pages/Color/ColorCreate";
import Request from "./pages/Admin/Request";
import Register from "./pages/Login/Register";
import ForgotPassword from "./pages/Login/ForgotPassword";
import Dashboard from "./pages/Dashboard";
import { useSelector } from "react-redux";
import Amplify from "aws-amplify";
import ProtectedRoute from "./utils/ProtectedRoute";
import { UserProfile } from "./pages/User/UserProfile";
import CelesToast from "./components/Loading/CelesToast";

Amplify.configure({
  Auth: {
    identityPoolId: "ap-southeast-1:e31daeef-8ec8-4cdf-a023-b071625c2836",
    region: "ap-southeast-1",
    userPoolId: "ap-southeast-1_dWneB0mmY",
    userPoolWebClientId: "52ij5oqg1q78h9t5eunjri95uk",
  },
  Storage: {
    bucket: "lezzoncolor9f81134a83164345a773713685d4e4bd140543-dev",
    region: "ap-southeast-1",
  },
});

const navContext = createContext();

export const useNavCtx = () => useContext(navContext);

function App() {
  const { user } = useSelector((state) => state.auth);

  const [currentNav, setCurrentNav] = useState(null);

  // console.log("Version : 1.0.0");
  //CelesToast
  return (
    <React.Fragment>
      <navContext.Provider value={{ currentNav, setCurrentNav }}>
        <CelesToast />
        <Router>
          {user && (
            <React.Fragment>
              <NavBar />
            </React.Fragment>
          )}
          <Route exact path="/Login" component={Login} />

          <div className="pt-44 xl:pt-36 ">
            <Switch>
              <Route path="/Register" component={Register} />
              <Route path="/ForgotPassword" component={ForgotPassword} />
              <ProtectedRoute path="/Dashboard" component={Dashboard} />
              <ProtectedRoute exact path="/" component={Home} />
              <ProtectedRoute exact path="/Color" component={ColorMain} />
              <ProtectedRoute path="/Color/Create" component={ColorCreate} />
              <ProtectedRoute path="/Color/:id" component={ColorEdit} />
              <ProtectedRoute exact path="/Setting" component={SettingMain} />
              <ProtectedRoute exact path="/User/:id" component={UserProfile} />
              <ProtectedRoute exact path="/Admin" component={Request} />
            </Switch>
          </div>
        </Router>
      </navContext.Provider>
    </React.Fragment>
  );
}

export default App;
