import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import api from "../../api";
import { useNavCtx } from "../../App";

const Card = ({
  title = "",
  number = "",
  img = "",
  textcolor = "",
  color = "",
}) => {
  return (
    <div
      className={`w-full widget  transform hover:scale-105 hover:shadow-lg duration-300 cursor-pointer  ${color} `}
    >
      <div className="widget w-full p-4 rounded-lg     bg-white border border-gray-100 dark:bg-gray-900 dark:border-gray-800">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-col">
            <div className="text-xs uppercase font-light text-gray-500">
              {title}
            </div>
            <div className={"text-3xl font-bold" + [textcolor]}> {number}</div>
          </div>
          <img className="w-auto h-10" src={img} />
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const router = useHistory();

  const { setCurrentNav } = useNavCtx();

  const pathName = router.location.pathname;

  const [cardInfos, setCardInfos] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCurrentNav(pathName);
  }, [pathName]);

  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = async () => {
    try {
      const { data: dashboard } = await api.get(`/dashboards`);
      const data = dashboard?.data;
      setCardInfos([
        {
          title: "All color formulas",
          number: data?.count_all_formula,
          color: "",
          textcolor: "",
          // img: "../icon/1.png",
        },
        {
          title: "All color formulas requested",
          number: data?.count_all_pending,
          color: " border border-solid  hover:border-yellow-400 ",
          textcolor: " text-yellow-400",
          // img: "../icon/5.png",
        },
        {
          title: "Number of private color formulas",
          number: data?.count_all_private,
          color: " border border-solid  hover:border-textblue ",
          textcolor: " text-textblue",
          // img: "../icon/2.png",
        },
        {
          title: "Number of public color formulas",
          number: data?.count_all_public,
          color: " border border-solid  hover:border-lime-500 ",
          textcolor: " text-lime-500",
          // img: "../icon/3.png",
        },
        {
          title: "All application requests",
          number: data?.count_all_register,
          color: " border border-solid  hover:border-yellow-400 ",
          textcolor: " text-yellow-400",
          // img: "../icon/5.png",
        },
        {
          title: "All rejected",
          number: data?.count_all_inactive,
          color: " border border-solid  hover:border-red-500 ",
          textcolor: " text-red-500",
          // img: "../icon/2.png",
        },

        {
          title: "All customers in the system",
          number: data?.count_all_user,
          color: " border border-solid  hover:border-waitingbuttoncolor ",
          textcolor: " text-waitingbuttoncolor",
          // img: "../icon/7.png",
        },
      ]);
    } catch (error) {
    } finally {
    }
  };

  return (
    <div className="px-4 md:px-8 lg:px-16 ">
      <div className="h-screen ">
        <div className="max-w-7xl w-full mx-auto py-6 sm:px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row lg:grid lg:grid-cols-3 lg:gap-2 w-full  space-y-2 lg:space-y-0 mb-2 lg:mb-4">
            {cardInfos?.map((cardInfo, index) => {
              return (
                <div key={index}>
                  <Card
                    color={cardInfo.color}
                    title={cardInfo.title}
                    number={cardInfo.number}
                    img={cardInfo.img}
                    textcolor={cardInfo.textcolor}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
