import React, { forwardRef } from "react";

//HAVE TO INSTALL FROM OUTSOURCE
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const checkFunctionToBeTrue = (input) => {
  if (input === undefined) {
    return false;
  } else if (input === null) {
    return false;
  } else if (input === true) {
    return true;
  } else {
    return true;
  }
};

export const createRangeYearToToDay = (startDate, size = 120) => {
  const objDate = startDate ? startDate : new Date().getFullYear();
  const startAt = objDate - size;
  return [...Array(size).keys()].map((i) => i + startAt);
};

export const getPathUrl = (input) => {
  return input;
};

export const AddUrlToFile = (file) => {
  if (!file) return;
  if (file) {
    const src = URL.createObjectURL(file);
    return src;
  }
};

export const titleStyle = "mb-2";

export const imageStyle = "mx-auto p-4";

export const defualtOptions = [
  { value: "text", label: "Text" },
  { value: "image", label: "Image" },
];

export const months_in_enlish = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const months_in_thai = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
];

export const RenderPicker = forwardRef((props) => {
  const { onClick, value, label, classNameLabel } = props;

  return (
    <div className="w-full flex-1 mb-2">
      <div className={classNameLabel ? classNameLabel : titleStyle}>
        {label}
      </div>
      <div
        onClick={onClick}
        className="bg-white  my-0 p-2 mt-1 flex border border-gray-200 rounded px-2 appearance-none outline-none w-full text-gray-800"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 mr-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
        {value}
      </div>
    </div>
  );
});

// export const ErrorMessageMulti = ({ error }) => {
//   return (
//     <div>
//       <div className="bg-red-50 border-l-8 border-red-900 my-2">
//         <div className="flex items-center">
//           <div className="p-2">
//             <div className="flex items-center">
//               <div className="ml-2">
//                 <svg
//                   className="h-8 w-8 text-red-900 mr-2 cursor-pointer"
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke="currentColor"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
//                   />
//                 </svg>
//               </div>
//               <p className="px-6 py-4 text-red-900 font-semibold text-lg">
//                 Please fix the following errors.
//               </p>
//             </div>
//             <div className="px-16 mb-4">
//               <li className="text-md font-bold text-red-500 text-sm">
//                 {error}
//               </li>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export const ErrorMessage = ({ error }) => {
  return (
    <div class="flex items-center bg-red-500 border-l-4 border-red-700 py-2 px-3 shadow-md mb-2 my-2">
      <div class="text-red-500 rounded-full bg-white mr-3">
        <svg
          width="1.8em"
          height="1.8em"
          viewBox="0 0 16 16"
          className="bi bi-x"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
          />
          <path
            fillRule="evenodd"
            d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
          />
        </svg>
      </div>
      <div class="text-white max-w-xs ">{error}</div>
    </div>
  );
};

export const onChangeForEverything = (
  event,
  setValue,
  name,
  setField,
  primaryField,
  setFieldMulti,
  index
) => {
  const event_value = event.target.value;
  const event_checked = event.target.checked;

  if (setField && event_value) {
    setField(name)(event_value);
  } else if (setField && event_checked) {
    setField(name)(event_checked);
  } else if (setValue && event_value) {
    setValue(event_value);
  } else if (setValue && event_checked) {
    setValue(event_checked);
  } else if (setFieldMulti && event_value && index && primaryField) {
    setFieldMulti(name, primaryField, index)(event_value);
  } else if (setFieldMulti && event_checked && index && primaryField) {
    setFieldMulti(name, primaryField, index)(event_checked);
  } else {
    return;
  }
};

export const FieldText = ({
  value,
  label = "label = {'name'}",
  disabled,
  type = "text",
  className = " bg-gray-100  text-textgray rounded-md w-full px-4 py-2 outline-none ",
  placeholder,
  setField = null,
  name,
  classNameLabel,
  unit = "",
  setValue = null,
  index = 0,
  primaryField,
  setFieldMulti = null,
  error = {},
  myOnChange = null,
}) => {
  const onChange = (e) => {
    if (setField) {
      setField(name)(e.target.value);
      if (myOnChange) {
        myOnChange(e.target.value);
      }
    } else if (setValue) {
      setValue(e.target.value);
    } else if (setFieldMulti) {
      setFieldMulti(name, primaryField, index)(e.target.value);
    } else if (myOnChange) {
      myOnChange(e.target.value);
    } else {
      return;
    }
  };

  return (
    <div className="  text-textgray rounded-md w-full px-4 py-2 outline-none ">
      <div className={classNameLabel ? classNameLabel : titleStyle}>
        {label}
      </div>
      <div className={unit ? "flex" : ""}>
        <div className=" rounded-md ">
          <input
            className={className}
            disabled={disabled}
            value={value}
            onChange={onChange}
            type={type}
            placeholder={placeholder}
          />
        </div>
      </div>
      {/* {name && [
        checkFunctionToBeTrue(error[name]) && (
          <ErrorMessage
            error={error[name]?.message ? error[name]?.message : error[name]}
          />
        ),
      ]} */}
    </div>
  );
};

export const FieldUploadImage = ({
  disabled = false,
  label = "label = {'name'}",
  setField,
  value,
  name,
  classNameLabel,
  error = {},
}) => {
  const onClickUpLoadImage = async ({ target: { files } }) => {
    if (setField) {
      const file = await files[0];
      setField(name)(file);
    } else {
      return;
    }
  };
  return (
    <div>
      <div className={classNameLabel ? classNameLabel : titleStyle}>
        {label}
      </div>
      <div
        className={value ? "my-4  border border-dashed  border-blue-500" : ""}
      >
        {value?.size > 0 ? (
          <img className={imageStyle} src={AddUrlToFile(value)} />
        ) : (
          [value ? <img className={imageStyle} src={getPathUrl(value)} /> : ""]
        )}
      </div>
      <div className="bg-blue-600 py-3 w-full md:my-2 my-4 rounded-md cursor-pointer">
        <label for="button-file">
          <div className=" flex justify-center items-center gap-2 cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clip-rule="evenodd"
              />
            </svg>
            <div className=" cursor-pointe text-center text-white">
              Upload Image
            </div>
            <input
              disabled={disabled}
              accept="image/jpeg,image/gif,image/png"
              className="hidden"
              id="button-file"
              type="file"
              onChange={onClickUpLoadImage}
            />
          </div>
        </label>
      </div>
      {/* {checkFunctionToBeTrue(error[name]) && (
        <ErrorMessage
          error={error[name]?.message ? error[name]?.message : error[name]}
        />
      )} */}
    </div>
  );
};

export const FieldDropdown = ({
  disabled,
  placeholder,
  setField,
  label = "label = {'name'}",
  name,
  value,
  className = "  text-textgray rounded-md w-full px-4 py-2 outline-none ",
  options = defualtOptions,
  classNameLabel = titleStyle,
  isMulti = false,
  other_name,
  other_value,
  setOtherValue,
  setValue,
  defaultValue,
  onInputChange,
}) => {
  const ckeck_other_true =
    String(value?.value ? value?.value : value)?.slice(0, 5) === "other"
      ? true
      : false;

  const onChange = (e) => {
    console.log("EVENT", e);
    if (setField) {
      setField(name)(e);
    } else if (setValue) {
      setValue(e);
    } else {
      return;
    }
  };
  return (
    <div>
      <div className={className}>
        <div className={classNameLabel ? classNameLabel : titleStyle}>
          {label}
        </div>
        <div>
          <Select
            isMulti={isMulti}
            isSearchable={true}
            isDisabled={disabled}
            options={options}
            value={
              options &&
              options[
                options?.length > 0
                  ? options?.findIndex((option) =>
                      option?.value
                        ? option?.value === value?.value
                        : option === value
                    )
                  : 0
              ]
            }
            onChange={onChange}
            onInputChange={onInputChange}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
          />
        </div>
        {ckeck_other_true && (
          <FieldText
            name={other_name}
            setField={setField}
            value={other_value}
            setValue={setOtherValue}
          />
        )}
      </div>
      {/* {checkFunctionToBeTrue(error[name]) && (
        <ErrorMessage
          error={error[name]?.message ? error[name]?.message : error[name]}
        />
      )} */}
    </div>
  );
};

export const FieldCheckbox = ({
  title = "title = {'name'} if don't have set title = `` ",
  name,
  value,
  setField,
  primaryField,
  setFieldMulti,
  index,
  classNameLabel = "md:text-lg text-base text-textblue",
  classNameTitle,
  label = "label = {'name'}",
  disabled = false,
  className = "flex justify-start items-center  space-x-2 rounded-xl my-4",
  error = {},
}) => {
  const onChangeCheckbox = (e) => {
    if (setField) {
      const value = e.target.checked;
      setField(name)(value);
    } else if (primaryField) {
      return setFieldMulti(name, primaryField, index)(value);
    } else {
      return;
    }
  };
  return (
    <div>
      <div className={classNameTitle ? classNameTitle : titleStyle}>
        {title}
      </div>
      <div className={className}>
        <input
          type="checkbox"
          name={name}
          checked={value}
          onChange={onChangeCheckbox}
          disabled={disabled}
        />
        <div className={classNameLabel}>{label}</div>
      </div>
      {/* {checkFunctionToBeTrue(error[name]) && (
        <ErrorMessage
          error={error[name]?.message ? error[name]?.message : error[name]}
        />
      )} */}
    </div>
  );
};

export const FieldCheckboxSelectOne = ({
  title = "title = {'name'} if don't have set title = `` ",
  name,
  value,
  setField,
  primaryField,
  setFieldMulti,
  index,
  classNameLabel = "md:text-lg text-base text-textblue",
  classNameTitle,
  label = ["label are array = `[`name`,`name2`]` "],
  disabled = false,
  className = "flex justify-start items-center  space-x-2 rounded-xl my-4",
  error = {},
}) => {
  const onChangeCheckbox = (e, row) => {
    if (setField) {
      setField(name)(row);
    } else if (primaryField) {
      return setFieldMulti(name, primaryField, index)(row);
    } else {
      return;
    }
  };
  return (
    <div>
      <div className={classNameTitle ? classNameTitle : titleStyle}>
        {title}
      </div>
      {label?.map((row, index) => {
        return (
          <div key={`${index}_classname`} className={className}>
            <input
              key={`${index}_input`}
              type="checkbox"
              name={name}
              checked={value}
              onChange={(e) => onChangeCheckbox(e, row)}
              disabled={disabled}
            />
            <div className={classNameLabel}>{label}</div>
          </div>
        );
      })}
      {/* {checkFunctionToBeTrue(error[name]) && (
        <ErrorMessage
          error={error[name]?.message ? error[name]?.message : error[name]}
        />
      )} */}
    </div>
  );
};

export const FieldRadio = ({
  title = "title = {'name'} if don't have set title = `` ",
  classNameTitle,
  classNameBox = "mt-2 flex justify-between items-center text-sm",
  label = ["label radio must be array"],
  value,
  setField,
  className = "container",
  name,
  primaryField,
  setFieldMulti,
  index,
  error = {},
}) => {
  const onChangeCheck = (e) => {
    if (setField) {
      const value = e.target.checked;
      setField(name)(value);
    } else if (primaryField) {
      return setFieldMulti(name, primaryField, index)(value);
    } else {
      return;
    }
  };

  return (
    <div>
      <div className={classNameTitle ? classNameTitle : titleStyle}>
        {title}
      </div>
      <div className={classNameBox}>
        {label?.map((row, index) => {
          return (
            <div key={`radio-${index}`}>
              <label className={className}>
                <input
                  type="radio"
                  checked={value === row}
                  onChange={onChangeCheck}
                />
                <span className="checkmark" />
                {row}
              </label>
            </div>
          );
        })}
      </div>
      {/* {checkFunctionToBeTrue(error[name]) && (
        <ErrorMessage
          error={error[name]?.message ? error[name]?.message : error[name]}
        />
      )} */}
    </div>
  );
};

export const FieldCheckBoxWithFieldDropdown = ({
  label = "label = {'name'}",
  title = "title = {'name'} if don't have set title = `` ",
  classNameTitle,
  name,
  value,
  disabled = false,
  setField,
  dropDownName,
  dropDownValue,
  options,
  classNameDropDown = "my-4",
  className = "flex justify-start items-center  space-x-2 rounded-xl my-4",
  placeholder,
  error = {},
}) => {
  const onChangeCheckbox = (e) => {
    if (setField) {
      const value = e.target.checked;
      setField(name)(value);
    } else {
      return;
    }
  };
  return (
    <div>
      <div className={classNameTitle ? classNameTitle : titleStyle}>
        {title}
      </div>
      <div className={className}>
        <input
          type="checkbox"
          name={name}
          checked={value}
          onChange={onChangeCheckbox}
          disabled={disabled}
        />
        <div className="md:text-lg text-base text-textblue">{label}</div>
      </div>
      {value && (
        <div>
          <FieldDropdown
            disabled={disabled}
            placeholder={placeholder}
            setField={setField}
            name={dropDownName}
            value={dropDownValue}
            className={classNameDropDown}
            options={options}
          />
        </div>
      )}
      {/* {checkFunctionToBeTrue(error[name]) && (
        <ErrorMessage
          error={error[name]?.message ? error[name]?.message : error[name]}
        />
      )} */}
    </div>
  );
};

export const FieldCheckBoxWithFieldText = ({
  label = "label = {'name'}",
  title = "title = {'name'} if don't have set title = `` ",
  classNameTitle,
  name,
  disabled = false,
  value,
  textFieldName,
  setField,
  textFieldValue,
  unit,
  error = {},
  className = "flex justify-start items-center  space-x-2 rounded-xl my-4",
}) => {
  const onChangeCheckbox = (e) => {
    if (setField) {
      const value = e.target.checked;
      setField(name)(value);
    } else {
      return;
    }
  };
  return (
    <div>
      <div className={classNameTitle ? classNameTitle : titleStyle}>
        {title}
      </div>
      <div className={className}>
        <input
          disabled={disabled}
          type="checkbox"
          name={name}
          checked={value}
          onChange={onChangeCheckbox}
        />
        <div className="md:text-lg text-base text-textblue">{label}</div>
      </div>
      {value && (
        <div className={unit ? "flex" : ""}>
          <FieldText
            disabled={disabled}
            placeholder="Name"
            name={textFieldName}
            setField={setField}
            value={textFieldValue}
          />
          {unit && <div className="my-auto ml-4 text-textinput">{unit}</div>}
        </div>
      )}
      {/* {checkFunctionToBeTrue(error[name]) && (
        <ErrorMessage
          error={error[name]?.message ? error[name]?.message : error[name]}
        />
      )} */}
    </div>
  );
};

export const FieldDatePicker = ({
  name,
  value,
  setField,
  showTime,
  label = "label = {'name'}",
  classNameLabel,
  disabled = false,
  className,
  error = {},
}) => {
  const onChange = (date) => {
    if (setField) {
      setField(name)(date);
    } else {
      return;
    }
  };
  return (
    <div className={className}>
      <DatePicker
        locale="th"
        dateFormat={"dd/MM/yyyy" + (showTime ? " HH:mm " : "")}
        showTimeSelect={showTime}
        timeFormat="HH:mm"
        readOnly={disabled}
        timeIntervals={15}
        selected={
          value === undefined
            ? null
            : typeof value === "object"
            ? value
            : new Date(value)
        }
        onChange={onChange}
        customInput={
          <RenderPicker label={label} classNameLabel={classNameLabel} />
        }
      />
      {/* {checkFunctionToBeTrue(error[name]) && (
        <ErrorMessage
          error={error[name]?.message ? error[name]?.message : error[name]}
        />
      )} */}
    </div>
  );
};

export const FieldDatePickerWithYearAndMonth = ({
  name,
  value,
  setField,
  label = "label = {'name'}",
  classNameLabel,
  className,
  disabled = false,
  error = {},
}) => {
  const years = createRangeYearToToDay();

  const onChange = (date) => {
    if (setField) {
      setField(name)(date);
    } else {
      return;
    }
  };

  return (
    <div className={className}>
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex justify-center my-2">
            <button
              className="mx-2 border border-gray-400 px-2"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {"<"}
            </button>
            <select
              value={new Date(date).getFullYear()}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months_in_enlish[new Date(date).getMonth()]}
              onChange={({ target: { value } }) =>
                changeMonth(months_in_enlish.indexOf(value))
              }
            >
              {months_in_enlish.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              className="mx-2 border border-gray-400 px-2"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {">"}
            </button>
          </div>
        )}
        selected={value}
        readOnly={disabled}
        onChange={onChange}
        customInput={
          <RenderPicker label={label} classNameLabel={classNameLabel} />
        }
      />
      {/* {checkFunctionToBeTrue(error[name]) && (
        <ErrorMessage
          error={error[name]?.message ? error[name]?.message : error[name]}
        />
      )} */}
    </div>
  );
};

export const FieldTimePicker = ({
  name,
  setField,
  label = "label = {'name'}",
  value,
  disabled = false,

  error = {},
}) => {
  const onChange = (e) => {
    if (setField) {
      const value = e.target.value;
      setField(name)(value);
    } else {
      return;
    }
  };
  return (
    <div>
      <div className="my-2">{label}</div>
      <input
        type="time"
        name={name}
        onChange={onChange}
        value={value}
        className="border border-gray-300 p-2 rounded-md"
        disabled={disabled}
      />
      {/* {checkFunctionToBeTrue(error[name]) && (
        <ErrorMessage
          error={error[name]?.message ? error[name]?.message : error[name]}
        />
      )} */}
    </div>
  );
};
