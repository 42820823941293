import Resizer from "react-image-file-resizer";
import { Storage } from "aws-amplify";

export const CloudfrontUrl = "https://d3isl7rjhemhjq.cloudfront.net/public/";

export const getPathUrl = (key) => {
  return `${CloudfrontUrl}${key}`;
};

export const AddUrlToFile = (file) => {
  if (!file) return;
  if (file) {
    const src = URL.createObjectURL(file);
    return src;
  }
};
export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });
export const getYears = (min = 1910) => {
  const today = new Date();
  const year = today.getFullYear();
  let options = [];
  for (var i = year; i >= min; i--) {
    options.push({ label: i, value: i });
  }
  return options;
};

export const createRangeYearToToDay = () => {
  const size = 120;
  const objDate = new Date().getFullYear();
  const startAt = objDate - size;
  return [...Array(size).keys()].map((i) => i + startAt);
};

export const FunctionSumOfArray = (Array) => {
  if (toString.call(Array) !== "[object Array]") return false;

  var total = 0;
  for (var i = 0; i < Array.length; i++) {
    if (isNaN(Array[i].gram)) {
      continue;
    }
    total += Number(Array[i].gram);
  }
  return total;
};

export const UploadFileAws = async (file) => {
  if (file) {
    const keyUpload = `${new Date().getTime()}_${file?.name}`;
    await Storage.put(keyUpload, file);
    return keyUpload;
  } else {
    return;
  }
};

// const x = [
//   {
//     name: "C",
//     density: 1.6,
//     kg: 1,
//   },
//   {
//     name: "M",
//     density: 1.8,
//     kg: 1.5,
//   },
//   {
//     name: "Y",
//     density: 2.5,
//     kg: 2.5,
//   },
//   {
//     name: "K",
//     density: 3,
//     kg: 3,
//   },

// ]

// export const FunctionSumOfArray = (Array) => {
//   if (toString.call(Array) !== "[object Array]") return false;

//   var total = 0;
//   for (var i = 0; i < Array.length; i++) {
//     if (isNaN(Array[i].kg)) {
//       continue;
//     }
//     total += Number(Array[i].kg)
//   }
//   return total;
// };

// export const FunctionSumOfGrum = (Array) => {
//   if (toString.call(Array) !== "[object Array]") return false;

//   var total = 0;
//   for (var i = 0; i < Array.length; i++) {
//     if (isNaN(Array[i].grum)) {
//       continue;
//     }
//     total += Number(Array[i].liter);
//   }
//   return total;
// };

// const color = x.map((row)=>{
// const percent = row.kg / FunctionSumOfArray(x)*100
//  const liter = row.kg * row.density

//  return({percent : percent , liter : liter})

// })

// console.log("color",color)

// const all_liter = FunctionSumOfDensity(color)

// console.log("all_liter : ",all_liter)
