import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import api from "../api";
import swalAlert from "../utils/swalAlert";

export const loginThunk = createAsyncThunk(
  "auth/token",
  async ({ username, password }) => {
    try {
      const userSignIn = await Auth.signIn({
        username: username,
        password: password,
      });
      if (userSignIn?.challengeName === "NEW_PASSWORD_REQUIRED") {
        await Auth.completeNewPassword(userSignIn, password);
      }

      const username_cognito = userSignIn.username;

      let user_mongo;
      let profiles;

      if (username_cognito) {
        user_mongo = await api.get(`/profiles?username=${username_cognito}`);
        profiles = user_mongo.data.profiles[0];
      }

      const active_user = profiles.status === "active";

      if (active_user) {
        return { user: profiles };
      } else {
        localStorage.clear();
        await swalAlert.Fail({ text: "This user is not active" });
        return { user: null };
      }
    } catch (error) {
      await swalAlert.Fail({ text: error.message });
      console.error("Auth error : ", error);
      return { user: null };
    }
  }
);

export const initAuth = createAsyncThunk(
  "initializeApp/initializeStore",
  async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      const username_cognito = user.username;

      let user_mongo;
      let profiles;

      if (username_cognito) {
        user_mongo = await api.get(`/profiles?username=${username_cognito}`);
        profiles = user_mongo.data.profiles[0];
      }

      const active_user = profiles.status === "active";

      if (active_user) {
        return { user: profiles };
      } else {
        return {
          user: null,
          status: "error",
        };
      }
    } catch (error) {
      return {
        user: null,
        status: "error",
      };
    }
  }
);

const initialState = { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [loginThunk.fulfilled]: (state, action) => {
      state.user = action.payload.user;
      state.isLoggedIn = true;
    },
    [initAuth.fulfilled]: (state, action) => {
      state.user = action.payload.user;
      state.isLoggedIn = true;
    },
    [loginThunk.pending]: (state, action) => {
      state.user = null;
      state.isLoggedIn = false;
    },
  },
});

export default authSlice.reducer;
