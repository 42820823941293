import React, { memo, useState, useEffect } from "react";
import { Router } from "react-router-dom/cjs/react-router-dom.min";
import api from "../../api";
import { useHistory } from "react-router-dom";
import swalAlert from "../../utils/swalAlert";
import useToastify from "../../hooks/useToastify";
import useSweetAlert from "../../hooks/useSweetAlert";

const ColorSystemCode = ({
  name = "",
  code = "",
  weight = "",
  milliliter = "",
  Thainame,
  deleteIngradient,
  id,
}) => {
  return (
    <div className="px-4 py-2 bg-white rounded-lg items-start my-4 ">
      <div>
        <div className="flex justify-between my-4">
          <div className="grid grid-cols-1 w-full ">
            <div>Tinter Code: {code}</div>
          </div>
          <svg
            onClick={() => deleteIngradient(id)}
            className="w-6 h-6 text-textred cursor-pointer "
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
          <div>Tinter Name: {name}</div>
          <div>Thai Name : {Thainame}</div>
          {/* <div>Weight (grams): {weight} </div> */}
          <div>SG density: {milliliter} </div>
        </div>
      </div>
    </div>
  );
};

const TinterCode = ({ index }) => {
  const router = useHistory();
  const [currentSystemCodeForInputCode, setCurrentSystemCodeForInputCode] =
    useState("");
  const [currentSystemCodeForInputName, setCurrentSystemCodeForInputName] =
    useState("");
  const [currentSystemCodeForInputWeight, setCurrentSystemCodeForInputWeight] =
    useState("");
  const [
    currentSystemCodeForInputMilliliter,
    setCurrentSystemCodeForInputMilliliter,
  ] = useState("");

  const [currentSystemCodeForNameTH, setCurrentSystemCodeForNameTH] =
    useState("");

  const [systemCodes, setSystemCodes] = useState([]);

  const [loading, setLoading] = useState("");
  const { notifySuccess, notifyError } = useToastify();
  const { confirm } = useSweetAlert();

  useEffect(() => {
    LoadColors();
  }, []);

  const LoadColors = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/colors`);
      const data_table = data?.colors?.map((row) => {
        return {
          code: row.color_code,
          name: row.color_name,
          name_th: row.color_name_th,
          grams: row.weight,
          milliliter: row.density,
          id: row._id,
        };
      });
      setSystemCodes(data_table);
    } catch (error) {
      console.error("ERROR TO LOAD COLORS :", error);
    } finally {
      setLoading(false);
    }
  };
  /// API
  const CreateTinterCode = async () => {
    if (
      currentSystemCodeForInputCode &&
      currentSystemCodeForInputName &&
      currentSystemCodeForNameTH &&
      // currentSystemCodeForInputWeight &&
      currentSystemCodeForInputMilliliter
    ) {
      setLoading(true);
      try {
        await api.post(`/colors`, {
          color_code: currentSystemCodeForInputCode,
          color_name: currentSystemCodeForInputName,
          color_name_th: currentSystemCodeForNameTH,
          // weight: Number(currentSystemCodeForInputWeight),
          density: Number(currentSystemCodeForInputMilliliter),
        });
        //ResetState
        setCurrentSystemCodeForInputCode("");
        setCurrentSystemCodeForInputName("");
        // setCurrentSystemCodeForInputWeight("");
        setCurrentSystemCodeForInputMilliliter("");
        setCurrentSystemCodeForNameTH("");
        LoadColors();
        notifySuccess();
      } catch (error) {
        console.error("ERROR TO CREAT TINTER :", error);
      } finally {
        setLoading(false);
      }
    } else {
      await swalAlert.Fail({ text: "Please fill in all the required information." });
    }
  };

  const clickDelete = async (id) => {
    await confirm({
      title: "Do you want to delete data?" ,
      callback: () => deleteIngradient(id),
    });
  };

  const deleteIngradient = async (id) => {
    await api.delete(`/colors/${id}`);
    LoadColors();
    notifySuccess();

    const DeleteTinterColorCode = async () => {
      setLoading(true);
      try {
        await api.post(`/formulas`, {
          color_code: currentSystemCodeForInputCode,
          color_name: currentSystemCodeForInputName,
          color_name_th: currentSystemCodeForNameTH,
          // weight: Number(currentSystemCodeForInputWeight),
          density: Number(currentSystemCodeForInputMilliliter),
        });

        LoadColors(); ///pull color again after delete in the table
      } catch (error) {
        console.error("ERROR TO DELETE TINTER :", error);
      } finally {
        setLoading(false);
      }
    };

    // };

    // let temp = [...systemCodes];
    // if (index > -1) {
    //   temp.splice(index, 1);
    // }
    // setSystemCodes(temp);
  };

  return (
    <div>
      <div className="flex justify-between items-center mt-10">
        <div className="text-xl">Tinter Code</div>
      </div>
      <div className="mt-10">
        <div className="grid grid-cols-1 my-2">
          <div>Tinter Code</div>
          <div className="flex justify-start items-center space-x-4 mt-2">
            <input
              className="w-full  focus:outline-none bg-bgseccion   px-4 py-2 rounded-lg"
              placeholder="  AE10 "
              value={currentSystemCodeForInputCode}
              onChange={(e) => setCurrentSystemCodeForInputCode(e.target.value)}
            />
          </div>
        </div>
        <div className=" grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="">
            <div>Tinter Name</div>
            <div className="flex justify-start items-center space-x-4 mt-2">
              <input
                className="w-full  focus:outline-none bg-bgseccion   px-4 py-2 rounded-lg"
                placeholder="  MASTER COLOUR COARSEALU"
                value={currentSystemCodeForInputName}
                onChange={(e) =>
                  setCurrentSystemCodeForInputName(e.target.value)
                }
              />
            </div>
          </div>
          <div className="">
            <div>Thai Name</div>
            <div className="flex justify-start items-center space-x-4 mt-2">
              <input
                className="w-full  focus:outline-none bg-bgseccion   px-4 py-2 rounded-lg"
                placeholder="Thai Name"
                value={currentSystemCodeForNameTH}
                onChange={(e) => setCurrentSystemCodeForNameTH(e.target.value)}
              />
            </div>
          </div>
          {/* <div className="">
            <div>Weight (grams)</div>
            <div className="flex justify-start items-center space-x-4 mt-2">
              <input
                className="w-full  focus:outline-none bg-bgseccion   px-4 py-2 rounded-lg"
                placeholder="10 grams"
                value={currentSystemCodeForInputWeight}
                onChange={(e) =>
                  setCurrentSystemCodeForInputWeight(e.target.value)
                }
              />
            </div>
          </div> */}
          <div className="">
            <div>SG density</div>
            <div className="flex justify-start items-center space-x-4 mt-2">
              <input
                className="w-full  focus:outline-none bg-bgseccion   px-4 py-2 rounded-lg"
                placeholder="10 grams"
                value={currentSystemCodeForInputMilliliter}
                onChange={(e) =>
                  setCurrentSystemCodeForInputMilliliter(e.target.value)
                }
                type="number"
              />
            </div>
          </div>
        </div>
        <div
          className="flex justify-center items-center space-x-2  mt-4 w-full ml-auto  md:w-40 rounded-lg p-2 cursor-pointer text-white bg-waitingbuttoncolor"
          onClick={() => CreateTinterCode()}
        >
          <svg
            className="w-6 h-6  "
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
          <div>Add</div>
        </div>
      </div>
      <div className=" bg-bgseccion rounded-lg w-full p-4  h-80 overflow-y-scroll mt-6  ">
        {systemCodes.map((systemCode, index) => {
          return (
            <div key={index}>
              <ColorSystemCode
                code={systemCode.code}
                name={systemCode.name}
                Thainame={systemCode.name_th}
                weight={systemCode.grams}
                milliliter={systemCode.milliliter}
                index={index}
                id={systemCode.id}
                deleteIngradient={clickDelete}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TinterCode;
