import React, { useState, useEffect, useCallback } from "react";
import CardColor from "../../components/Card/CardColor";
import api from "../../api";
import { useHistory } from "react-router-dom";
import { ColorController } from "./ColorController";
import { getPathUrl } from "../../Functions";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";
import { useNavCtx } from "../../App";
import Swal from "sweetalert2";

const ColorMain = () => {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formulas, setFormulas] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [radio, setRadio] = useState("PRIVATE");

  const router = useHistory();
  const { user } = useSelector((state) => state.auth);

  const { setCurrentNav } = useNavCtx();
  const pathName = router.location.pathname;

  useEffect(() => {
    setCurrentNav(pathName);
  }, [pathName]);

  useEffect(() => {
    if (user._id) {
      // console.log("filterValue", filterValue);
      onChangeSearch();
    } else {
      return;
    }
  }, [form, radio, user]);

  useEffect(() => {
    if (filterValue) {
      LoadFormulas();
    } else {
      return;
    }
  }, [filterValue]);

  const LoadFormulas = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/formulas${filterValue && `${filterValue}`}`
      );
      setFormulas(data?.formulas);
    } catch (error) {
      console.error("Error to load formulas :", error);
    } finally {
      setLoading(false);
    }
  };

  const setField = useCallback(
    (field) => (e) => {
      const value = e && e.target ? e.target.value : e;
      setForm((currentForm) => ({
        ...currentForm,
        [field]: value,
      }));
    },
    [setForm]
  );

  const onChangeSearch = () => {
    console.time();
    let temp = [];

    if (form?.color_code?.length > 0) {
      const color_code = `&color_code=${form?.color_code}`;
      // console.log("IN1 : ", color_code);

      temp.push(color_code);
    }
    if (form?.color_ref?.length > 0) {
      const color_ref = `&color_ref=${form?.color_ref}`;
      // console.log("IN2 : ", color_ref);
      temp.push(color_ref);
    }
    if (form?.color_name?.length > 0) {
      const color_name = `&color_name=${form?.color_name}`;
      // console.log("IN3 : ", color_name);
      temp.push(color_name);
    }
    if (form?.color_system?.label) {
      const color_system = `&color_system.label=${form?.color_system?.label}`;
      // console.log("IN4 : ", color_system);
      temp.push(color_system);
    }
    if (form?.color_group?.label) {
      const color_group = `&color_group.label=${form?.color_group?.label}`;
      // console.log("IN5 : ", color_group);
      temp.push(color_group);
    }
    if (form?.manufacturer?.label) {
      const manufacturer = `&manufacturer.label=${form?.manufacturer?.label}`;
      // console.log("IN6 : ", manufacturer);
      temp.push(manufacturer);
    }
    if (form?.model?.label) {
      const model = `&model.label=${form?.model?.label}`;
      // console.log("IN7 : ", model);
      temp.push(model);
    }
    if (form?.country?.label) {
      const country = `&country.label=${form?.country?.label}`;
      // console.log("IN8 : ", country);
      temp.push(country);
    }
    if (form?.region?.label) {
      const region = `&region.label=${form?.region?.label}`;
      // console.log("IN9 : ", region);
      temp.push(region);
    }
    if (form?.year?.label) {
      const year = `&year.label=${form?.year?.label}`;
      // console.log("IN10 : ", year);
      temp.push(year);
    }

    // console.log("ID : ", user._id);
    // console.log("radio", radio);

    const default_filter = `?limit=50&type=${
      radio === "PRIVATE" ? `PRIVATE&maker.id=${user._id}` : radio
    }`;

    // console.timeEnd();

    if (default_filter?.length > 0) {
      if (temp?.length > 0) {
        const filter = temp?.join("");
        setFilterValue(`${default_filter + filter}`);
      } else {
        setFilterValue(`${default_filter}`);
      }
    } else {
      return;
    }
  };

  const deleteColor = async (e, id) =>{
    e.stopPropagation();
    Swal.fire({
      title: "Do you want to delete data?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `NO`,
    }).then(async (result)=>{
      if (result.isConfirmed) 
      {
        try {
          setLoading(true);
          await api.delete(`/formulas/${id}`);
        } catch (error) {
          console.log("formulas error", error);
          setError(error);
        } finally {
          setLoading(false);
          // console.log("ID",ID)
          LoadFormulas();
        }} else{
          return
        }
      })
  }

  return (
    <div className="px-4 md:px-8 lg:px-16 mb-10 bg-white flex  flex-col justify-center items-center  ">
      <div className="text-3xl">Color Online</div>
      <ColorController form={form} setField={setField} />
      <div className="w-full">
        <div className=" flex flex-col md:flex-row  justify-end items-center space-x-4 my-10 ">
          <div className="flex justify-center items-center space-x-4 mt-4">
            <label className="container">
              Private
              <input
                type="radio"
                checked={radio === "PRIVATE"}
                onClick={() => setRadio("PRIVATE")}
                name="radio"
              />
              <span className="checkmark" />
            </label>
            <label className="container">
              Public
              <input
                type="radio"
                checked={radio === "PUBLIC"}
                onClick={() => setRadio("PUBLIC")}
                name="radio"
              />
              <span className="checkmark" />
            </label>
          </div>

          <button
            onClick={() => router.push("/Color/Create")}
            className=" focus:outline-none rounded-md mt-4 md:mt-0  bg-textblue w-40 py-2 text-white text-lg flex justify-center items-center "
          >
            <svg
              className="w-5 h-5 border mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
            Create Colour
          </button>
          {/* </a> */}
        </div>
        <div className="px-20 w-full grid grid-cols-2 gap-4 md:gap-10 md:grid-cols-2 lg:grid-cols-3 mt-10">
          {loading ? (
            <Loading />
          ) : (
            <React.Fragment>
              {formulas?.map((card, index) => {
                return (
                  <div key={index}>
                    <CardColor
                      name={card?.color_name}
                      code={card?.color_code}
                      colors={" bg-red-500"}
                      index={index}
                      id={card?._id}
                      deleteColor={deleteColor}
                      type={card?.type}
                      maker={
                        card?.maker?.firstname + " " + card?.maker?.lastname
                      }
                      manufacturer={card?.manufacturer?.label}
                      manufacturingYear={card?.year?.label}
                      image={
                        card?.color_image ? getPathUrl(card?.color_image) : ""
                      }
                      updatedAt={card?.updatedAt}
                      radio={radio}
                    />
                  </div>
                );
              })}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
export default ColorMain;
