import Swal from "sweetalert2";

const useSweetAlert = () => {
  const confirm = async (
    options = {
      title: "ยืนยันการบันทึกใช่หรือไม่ ?",
      text: "",

      callback: () => {},
      other: {},
    }
  ) => {
    try {
      const resultSwalAlert = await Swal.fire({
        title: options.title,
        showCancelButton: true,
        confirmButtonText: `confirm`,
        cancelButtonText: "cancel",
        showLoaderOnConfirm: true,
        showLoaderOnDeny: false,
        preConfirm: async () => {
          return await options.callback();
        },
        ...options.other,
      });
      return resultSwalAlert;
    } catch (error) {
      console.error("Er", error);
    }
  };

  const warning = async (
    options = { title: "WARNING", text: "", other: {} }
  ) => {
    try {
      return await Swal.fire({
        title: options.title,
        text: options.text,
        icon: "warning",
        confirmButtonText: "ยืนยัน",
        ...options.other,
      });
    } catch (error) {}
  };

  return { confirm, warning };
};

export default useSweetAlert;
